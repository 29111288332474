import React from 'react'
import { Numbers } from 'Components/LocalResources/FindHelpResource'
import { outlinePhoneIconPath } from 'Shared/Icons/LocationInfo/paths'
import styled from 'styled-components'

import { formatPhoneNumber } from '../../../../Shared/helpers'
import LocationInfo from '../LocationInfo/LocationInfo'

interface PhoneNumberProps {
  numbers: Numbers
  alignitems?: string
}

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const PhoneNumber: React.FC<PhoneNumberProps> = ({ numbers, alignitems }) => {
  if (!numbers?.primary) return null

  const { primary, extension } = numbers

  const formatPhoneNumberExtension = (extension?: string) =>
    extension ? `Ext. ${extension}` : ''

  const renderPhoneNumber = (primary: string, extension?: string) => (
    <StyledLink href={`tel:+1${primary}`}>
      {`${formatPhoneNumber(primary)} ${formatPhoneNumberExtension(extension)}`}
    </StyledLink>
  )

  return (
    <LocationInfo
      alignitems={alignitems}
      content={renderPhoneNumber(primary, extension)}
      screenReaderLabel={'primary phone number'}
      icon={outlinePhoneIconPath}
    />
  )
}

export default PhoneNumber
