import React, { useEffect, useState } from 'react'
import MediaQuery from 'react-responsive'
import { filterLocations } from 'Actions/filters'
import { updateCoordinates } from 'Actions/geolocation'
import LocationRequest from 'Pages/LocationRequest/LocationRequest'
import {
  desktopAndTabletMediaQuery,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import { useAppDispatch, useAppSelector } from 'Store/hooks'
import useDeepCompareEffect from 'use-deep-compare-effect'

import DesktopLocations from './DesktopLocations'
import MobileLocations from './MobileLocations'

export type LocationsPageProps = {
  showDesktopDetailView?: boolean
}

const Locations = (props: LocationsPageProps) => {
  const { showDesktopDetailView = false } = props

  const dispatch = useAppDispatch()
  const { quickFilters, distance, query, serviceTags } = useAppSelector(
    (state) => state.filters
  )

  const coordinates = useAppSelector((state) => state.geolocation.coordinates)
  const zipCode = useAppSelector((state) => state.geolocation?.resourcesZipCode)
  const registeredZipCode = useAppSelector((state) => state.client?.zipCode)
  const searchTerm = useAppSelector((state) => state.filters.query)
  const [zipCodeError, setZipCodeError] = useState(false)
  const [searchThisArea, setSearchThisArea] = useState(false)
  const [showSearchThisAreaButton, setShowSearchThisAreaButton] =
    useState(false)
  const [initialPageLoad, setInitialPageLoad] = useState(true)
  setTimeout(() => {
    setInitialPageLoad(false)
  }, 1000)

  useDeepCompareEffect(() => {
    dispatch(filterLocations(true))
  }, [quickFilters, serviceTags, distance, query, zipCode, searchTerm])

  useDeepCompareEffect(() => {
    if (initialPageLoad) return
    setShowSearchThisAreaButton(true)
  }, [coordinates])

  useEffect(() => {
    if (searchThisArea) {
      dispatch(updateCoordinates(coordinates))
      dispatch(filterLocations())
      setShowSearchThisAreaButton(false)
      setSearchThisArea(false)
    }
  }, [searchThisArea])

  const hasZipCode = !!(registeredZipCode ?? zipCode)

  return (
    <>
      {!hasZipCode && <LocationRequest />}

      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        <MobileLocations
          registeredZipCode={registeredZipCode}
          searchTerm={searchTerm}
          setSearchThisArea={setSearchThisArea}
          showSearchThisAreaButton={showSearchThisAreaButton}
          setZipCodeError={setZipCodeError}
          zipCodeError={zipCodeError}
        />
      </MediaQuery>

      <MediaQuery query={desktopAndTabletMediaQuery}>
        <DesktopLocations
          showDesktopDetailView={showDesktopDetailView}
          showHeader={true}
          registeredZipCode={registeredZipCode}
          searchTerm={searchTerm}
          showSearchThisAreaButton={showSearchThisAreaButton}
          setSearchThisArea={setSearchThisArea}
          setZipCodeError={setZipCodeError}
          zipCodeError={zipCodeError}
        />
      </MediaQuery>
    </>
  )
}

export default Locations
