import React from 'react'
import { disableLogoRedirect } from 'Shared/helpers'

import SingleStopLogoLink from './SingleStopLogoLink'
import { StyledLogoLink } from './style'

type SingleStopLogoProps = {
  width?: string
  margin?: string
  display?: string
  justifyContent?: string
  marginLeft?: string
}

const SingleStopLogo = ({
  width,
  margin,
  display,
  justifyContent,
  marginLeft,
}: SingleStopLogoProps) => {
  const handleClick = (e) => {
    if (disableLogoRedirect()) {
      e.preventDefault()
    }
  }
  return (
    <StyledLogoLink
      display={display}
      justifyContent={justifyContent}
      marginLeft={marginLeft}
      href='/'
      onClick={handleClick}
    >
      <SingleStopLogoLink width={width} margin={margin} />
    </StyledLogoLink>
  )
}
export default SingleStopLogo
