import React from 'react'
import { API_BASE } from 'Shared/constants'

const URL = `${API_BASE}/community_resources/programs/service_tags`

type ServiceTag = {
  id: string
  label: string
  count: string
}

type ServiceTagsResponse = {
  tags: ServiceTag[]
}

const useCommunityResourcesServiceTags = (zipCode: string) => {
  const [serviceTags, setServiceTags] = React.useState<ServiceTag[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string | null>(null)

  React.useEffect(() => {
    const fetchServiceTags = async () => {
      try {
        const response = await fetch(`${URL}?zip_code=${zipCode}`)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data: ServiceTagsResponse = await response.json()
        setServiceTags(data.tags)
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message)
        } else {
          setError('An unknown error occurred')
        }
      } finally {
        setLoading(false)
      }
    }

    fetchServiceTags()
  }, [])

  return React.useMemo(
    () => ({ serviceTags, loading, error }),
    [serviceTags, loading, error]
  )
}

export default useCommunityResourcesServiceTags
