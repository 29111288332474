import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

export const StyledParentFilter = styled.div`
  @media (max-width: 574px) {
    position: absolute;
    width: 100%;
    height: 94vh;
    background-color: #fff;
    padding: 1.4rem;
    z-index: 99999;
    top: 4rem;
    left: 0;
    border-radius: 24px 24px 0px 0px;
    box-shadow: 0px -8vh 0px #1d283194;
  }
  @media (min-width: 575px) and (max-width: 1025px) {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 99999;
    top: 0;
    left: 0;
  }
`

export const StyledHeaderFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 2rem;
  ${media.smaller`
  padding: 0.875rem .25rem;
  margin-bottom: 1.25rem;
  `}
  ${media.tablet`
    padding: 1.25rem .6rem 1.25rem 1.875rem;
    margin-bottom: 1.25rem;
  `}
`

export const StyledFilterClearButton = withStyles({
  root: {
    color: '#29283E',
    textAlign: 'center',
    fontWeight: 500,
    fontFamily: 'inherit',
    fontSize: '0.9375rem',
    textDecoration: 'underline',
    padding: '0',
    minWidth: 'auto',
    position: 'relative',
    top: '0.9rem',
  },
})(Button)

export const StyledFilterMenu = styled.div`
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  padding: 0 2rem;
  height: calc(100vh - 195px);
  &::-webkit-scrollbar {
    display: none;
  }
  ${media.smaller`
  padding: 0;
  height: 91%;
        overflow: hidden;
  `}
  ${media.tablet`
  padding: 0 !important;
    height: 77vh;
        overflow: hidden;
  `}
  ${media.desktop`
  padding: 0 2rem;
  `}
`
export const StyledFilterTitle = styled.h2`
  font-weight: 700;
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 0.8em;
`
export const StyledFilterRow = styled.div`
  margin-bottom: 1.8rem;
`

type StyledCategoriesFilterBtnProps = {
  $categoriesFilterActionsDesktop?: boolean
  $categoriesFilterActions?: boolean
  $isResult?: boolean
}

export const StyledFilterFlex = styled.div<StyledCategoriesFilterBtnProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  ${({ $categoriesFilterActions }) =>
    $categoriesFilterActions &&
    css`
      height: 5.34rem;
    `}
`

export const StyledFilterBtn = styled.button<StyledCategoriesFilterBtnProps>`
  width: 100%;
  min-height: 4rem;
  box-shadow: 0px 11px 7px #0000000f;
  border: 1px solid #efefef;
  border-radius: 4px;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 400;
  cursor: pointer;
  ${media.desktop`
    min-height: 3.375rem;
    margin-bottom: 1rem;
  `}
  ${({ $categoriesFilterActionsDesktop }) =>
    $categoriesFilterActionsDesktop &&
    css`
      box-shadow: 5px 5px 20px 0px #aeaeae40;
      background-color: ${theme.palette.text.dark};
      color: ${theme.palette.color.white};
      width: 6.75rem;
      min-height: 3rem !important;
      margin-bottom: unset !important;
      border-radius: 50px;
      text-align: center;
      margin-left: 1rem;
      font-size: 0.875rem;
      font-weight: 600;
    `}
  ${({ $categoriesFilterActions }) =>
    $categoriesFilterActions &&
    css`
      box-shadow: unset;
      background-color: unset;
      color: ${theme.palette.text.dark};
      width: 48%;
      border-radius: 50px;
      text-align: center;
    `}
  ${({ $isResult }) =>
    $isResult &&
    css`
      box-shadow: unset;
      background-color: ${theme.palette.primary.darken30};
      color: ${theme.palette.color.white};
      border: unset;
    `}
`

export const StyledFilterBackLevelButton = styled.button`
  width: auto;
  height: auto;
  background: unset;
  padding: 0;
  border: 0;
`

export const StyledSubHeading = styled.h2`
  text-align: left;
  color: ${theme.palette.text.darken};
  font-size: 1.75rem;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 300;
  display: flex;
  @media only screen and (max-width: 570px) {
    font-size: 1.325rem;
    font-weight: 500;
  }
  @media only screen and (max-width: 1024px) {
    & svg {
      stroke: #151515;
    }
  }
  &:focus {
    outline: 0;
  }
`

StyledParentFilter.displayName = 'StyledParentFilter'
StyledHeaderFilter.displayName = 'StyledHeaderFilter'
StyledFilterMenu.displayName = 'StyledFilterMenu'
StyledFilterTitle.displayName = 'StyledFilterTitle'
StyledFilterRow.displayName = 'StyledFilterRow'
StyledFilterFlex.displayName = 'StyledFilterFlex'
StyledFilterBtn.displayName = 'StyledFilterBtn'
StyledFilterBackLevelButton.displayName = 'StyledFilterBackLevelButton'
StyledSubHeading.displayName = 'StyledSubHeading'
