import React from 'react'

import {
  StyledTabletAndDesktopSearchBar,
  StyledTabletAndDesktopSearchBarInner,
} from './style'

interface SearchBarProps {
  children: React.ReactNode
  className?: string
}
const SearchBar = ({ children, className }: SearchBarProps) => (
  <StyledTabletAndDesktopSearchBar className={className}>
    <StyledTabletAndDesktopSearchBarInner>
      {children}
    </StyledTabletAndDesktopSearchBarInner>
  </StyledTabletAndDesktopSearchBar>
)

export default SearchBar
