import React from 'react'
import { useDispatch } from 'react-redux'
import { Maybe, Person } from '__generated__/graphql'
import EditIcon from '@material-ui/icons/Edit'
import CaseTitle from 'Components/ClientPortal/MyBenefits/CaseCard/CaseTitle'
import CustomCaseStatus from 'Components/ClientPortal/MyBenefits/CaseCard/CustomCaseStatus/CustomCaseStatus'
import {
  StyledBenefitStatusWrapper,
  StyledCasesCard,
} from 'Components/ClientPortal/MyBenefits/style'
import { openBenefitModal } from 'Reducers/myBenefitsSlice'
import { isSpacebarOrEnterKey } from 'Shared/helpers'

type CaseCardProps = {
  name: string
  eligibility: string
  status: 'ACTIVE' | 'INACTIVE'
  id: string
  people: Maybe<Person[]> | undefined
  description: string
  openEligibilityKey: string
  enableEdit?: boolean
}
const CaseCard = ({
  name,
  eligibility,
  status,
  id,
  people,
  description,
  openEligibilityKey,
  enableEdit = false,
}: CaseCardProps) => {
  const dispatch = useDispatch()
  //ToDo implement the real callback when API is ready
  const handleEdit = () => {
    alert('open edit case status modal')
  }
  return (
    <StyledCasesCard
      key={id}
      data-testid={'service-case-card'}
      id={`service-case-card-${id}`}
    >
      <CustomCaseStatus status={status} />
      <CaseTitle
        name={name}
        handleClickOpen={() => {
          dispatch(
            openBenefitModal({
              benefitName: name,
              benefitDescription: description,
              openEligibilityKey,
            })
          )
        }}
      />
      <StyledBenefitStatusWrapper>
        <div>Benefit Status:</div>
        <div className='StyledBenefitStatusWrapper__info'>{eligibility}</div>
        {enableEdit && (
          <EditIcon
            tabIndex={0}
            style={{ color: '#0d8762', cursor: 'pointer', fontSize: '2rem' }}
            onClick={handleEdit}
            onKeyDown={(evt) => {
              if (isSpacebarOrEnterKey(evt.keyCode)) {
                handleEdit()
              }
            }}
          />
        )}
      </StyledBenefitStatusWrapper>
      <StyledBenefitStatusWrapper>
        {people && <span>`For ${people.join(', ')}`</span>}
      </StyledBenefitStatusWrapper>
    </StyledCasesCard>
  )
}

export default CaseCard
