import React from 'react'
import TabPanel from '@material-ui/lab/TabPanel'
import CaseCategoryHeading from 'Components/CaseManagement/ClientSummary/Cases/CaseCategoryHeading'
import { sortCaseMainCategories } from 'Components/CaseManagement/helpers'
import CaseCard from 'Components/ClientPortal/MyBenefits/CaseCard/CaseCard'
import { ParsedServiceCase } from 'Components/ClientPortal/MyBenefits/helpers'
import {
  StyledCaseCategoryWrapper,
  StyledCasesWrapper,
} from 'Components/ClientPortal/MyBenefits/style'
import { Dictionary } from 'lodash'

type MyBenefitsTabContentProps = {
  tabValue: 'ALL' | 'ACTIVE' | 'INACTIVE'
  cases: Dictionary<ParsedServiceCase[]>
}

const MyBenefitsTabContent = ({
  tabValue,
  cases,
}: MyBenefitsTabContentProps) => (
  <TabPanel value={tabValue} className='benefits-cards-wrapper'>
    {Object.keys(cases).length ? (
      Object.keys(cases)
        .sort(sortCaseMainCategories)
        .map((key) => (
          <StyledCaseCategoryWrapper key={key}>
            <CaseCategoryHeading
              openEligibilityKey={key}
              hideName={false}
              margin={''}
            />
            <StyledCasesWrapper>
              {cases[key].map((serviceCase) => (
                <CaseCard
                  key={serviceCase.id}
                  name={serviceCase.name}
                  eligibility={serviceCase.eligibility}
                  status={serviceCase.status}
                  id={serviceCase.id}
                  people={serviceCase.people}
                  description={serviceCase.description}
                  openEligibilityKey={serviceCase.openEligibilityKey}
                />
              ))}
            </StyledCasesWrapper>
          </StyledCaseCategoryWrapper>
        ))
    ) : (
      <StyledCaseCategoryWrapper>
        <div style={{ padding: '1rem' }} className='dashed-border'>
          No {tabValue.toLowerCase()} benefits
        </div>
      </StyledCaseCategoryWrapper>
    )}
  </TabPanel>
)

export default MyBenefitsTabContent
