import { gql } from '__generated__/gql'

export const CALCULATE_SCREENING_RESULTS = gql(`
  mutation CalculateScreeningResults($clientId: ID!) {
    calculateScreeningResults(clientId: $clientId) {
      screeningResults {
        benefitTotals {
          totalEligibleAmount
          cashBenefitEligibleAmount
          nonCashBenefitEligibleAmount
        }
      }
      screening {
        id
        serviceCases {
          id
          eligibility
          eligibleAmount
          service {
            name
            __typename
            mainCategory {
              id
              name
              __typename
              openEligibilityKey
            }
            description
            id
            url
            toolkitUrl
            frequency
            #ToDo uncomment when this is available in the API
            amountLabel
          }
          __typename
          people {
            fullName
          }
          clientLocation {
            id
            followUp
          }
        }
      }
    }
 }`)

export const RECALCULATE_SCREENING_RESULTS = gql(`
  mutation RecalculateScreeningResults($clientId: ID!) {
    recalculateScreeningResults(clientId: $clientId) {
      screening {
        id
        serviceCases {
          id
          eligibility
          eligibleAmount
          service {
            name
            __typename
            mainCategory {
              id
              name
              __typename
              openEligibilityKey
            }
            description
            id
            url
            toolkitUrl
            frequency
            #ToDo uncomment when this is available in the API
            amountLabel
          }
          __typename
          people {
            fullName
          }
          clientLocation {
            id
            followUp
          }
        }
      }
    }
 }`)
