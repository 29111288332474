import React from 'react'
import ContinueIcon from 'Components/Shared/Icon/ContinueButtonIcon'
import theme from 'Shared/Theme/ssTheme'
import { Col, Row } from 'styled-bootstrap-grid'

import {
  handleBirthDateAndAge,
  handleCheckboxes,
  handleInputFieldArray,
  handleMilitaryDateSelector,
  handleOnClickCallbacks,
  handleSelectMenuFieldArray,
  handleYesOrNoQuestions,
} from './helpers'
import { StyledDynamicCtaButton } from './style'

const CallsToAction = (props) => {
  const {
    callbacks,
    categoryIterator,
    checkboxFilterComparator,
    checkboxType,
    component,
    filtered,
    handleSubmit,
    history,
    iterable,
    name,
    navigation: { nextButton, extraButton },
    stepNumber,
  } = props

  const { handleNavigationButtonClick, handlePatchScreenerError } = callbacks

  const getNextButtonText = () => {
    if (component === 'YesNoQuestion') {
      return 'yes'
    } else {
      return nextButton.buttonText ? nextButton.buttonText : 'continue'
    }
  }

  const nextButtonText = getNextButtonText()

  /**
   * Handles submit on each screenerwizard form
   *
   * @param  {[type]} buttonName [description]
   * @return {[type]}            [description]
   */
  const handleSubmitAndNavigate = (buttonName, nextButton, callbacks) => {
    Promise.resolve(handleSubmit())
      .then((validationErrors) => {
        if (!validationErrors) {
          handleOnClickCallbacks(nextButton, callbacks)
          handleNavigationButtonClick(stepNumber, buttonName)
        }
      })
      .catch((error) => {
        handlePatchScreenerError(error, history)
      })
  }

  return (
    <>
      <Row>
        <Col col={extraButton ? '6' : '12'}>
          <StyledDynamicCtaButton
            data-testid='ctaMainButton'
            width={props.width || '100%'}
            margin={props.margin}
            name={'next'}
            type={'submit'}
            bghover={theme.palette.primary.dark}
            $hasExtraButton={extraButton}
            variant={extraButton ? 'outlined' : 'contained'}
            onClick={(event) => {
              event.preventDefault()
              handleBirthDateAndAge(callbacks, component, name, filtered)
              handleMilitaryDateSelector(
                callbacks,
                component,
                name,
                filtered,
                iterable
              )
              handleCheckboxes(
                callbacks,
                checkboxFilterComparator,
                checkboxType,
                component,
                name
              )
              handleInputFieldArray(
                callbacks,
                categoryIterator,
                component,
                filtered,
                iterable,
                name
              )
              handleSelectMenuFieldArray(
                callbacks,
                component,
                filtered,
                iterable,
                name
              )
              handleYesOrNoQuestions(
                'next',
                callbacks,
                component,
                filtered,
                iterable,
                name
              )

              handleSubmitAndNavigate('nextButton', nextButton, callbacks)
            }}
            $isYesNoQuestion={component === 'YesNoQuestion'}
          >
            {nextButtonText}
            {!extraButton && <ContinueIcon />}
          </StyledDynamicCtaButton>
        </Col>

        <Col col='6'>
          {extraButton && (
            <StyledDynamicCtaButton
              data-testid='ctaExtraButton'
              width={props.width || '100%'}
              margin={props.margin}
              name={'extra'}
              type={'button'}
              $hasExtraButton={extraButton}
              variant={'outlined'}
              onClick={() => {
                handleYesOrNoQuestions(
                  'extra',
                  callbacks,
                  component,
                  filtered,
                  iterable,
                  name
                )

                handleSubmitAndNavigate('extraButton', extraButton, callbacks)
              }}
              $isYesNoQuestion={component === 'YesNoQuestion'}
            >
              {extraButton.buttonText || 'no'}
            </StyledDynamicCtaButton>
          )}
        </Col>
      </Row>
    </>
  )
}

export default CallsToAction
