import React from 'react'

import { StyledHiddenRadio, StyledRadioLabel } from '../style'

interface StyledRadioButtonProps {
  id: string
  name: string
  value: string
  $isChecked: boolean
  label: string
  $variant?: 'primary' | 'secodary' | 'tertiary'
}

const StyledRadioButton: React.FC<StyledRadioButtonProps> = ({
  id,
  name,
  value,
  $isChecked,
  label,
  $variant = 'primary',
}) => (
  <>
    <StyledHiddenRadio
      data-testid={id}
      id={id}
      name={name}
      component='input'
      type='radio'
      value={value}
      checked={$isChecked}
    />
    <StyledRadioLabel $variant={$variant} htmlFor={id} $isChecked={$isChecked}>
      {label}
    </StyledRadioLabel>
  </>
)

export default StyledRadioButton
