import React from 'react'
import Icon from 'Components/Shared/Icon/Icon'
import searchAreaIconPath from 'Shared/Icons/searchAreaIconPath'

import { StyledMapSearchButton } from '../style'

const SearchThisAreaButton = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <StyledMapSearchButton onClick={handleClick}>
      <Icon
        fill='none'
        scale='1.1rem'
        viewBox={'0 0 24 24'}
        screenReaderLabel={'Search Icon'}
        margin='0 8px 0 0'
      >
        {searchAreaIconPath}
      </Icon>
      Search this area
    </StyledMapSearchButton>
  )
}

export default SearchThisAreaButton
