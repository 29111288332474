import React from 'react'
import { FindHelpResource } from 'Components/LocalResources/FindHelpResource'
import {
  StyledBorderBottom,
  StyledExpandedCardContent,
} from 'Components/LocalResources/LocationCard/style'
import BusinessHours from 'Components/Shared/LocationDetails/BusinessHours/BusinessHours'
import ProgramDescription from 'Components/Shared/LocationDetails/Description'
import Distance from 'Components/Shared/LocationDetails/Distance/Distance'
import EmailAddress from 'Components/Shared/LocationDetails/EmailAddress/EmailAddress'
import Languages from 'Components/Shared/LocationDetails/Languages'
import PhoneNumber from 'Components/Shared/LocationDetails/PhoneNumber'
import { StyledCard } from 'Components/Shared/LocationDetails/style'

import CategoriesComponent from '../CategoriesComponent/CategoriesComponent'
import { CustomStyledH3 } from '../CategoriesComponent/style'
import NextStepsComponent from '../NextStepsComponent/NextStepsComponent'

const MainContent = ({
  location,
}: {
  location: FindHelpResource
  padding: string
}) => {
  const {
    hours,
    distance,
    address,
    emailAddress,
    languageAccommodation: languages,
    numbers,
    description,
  } = location
  return (
    <StyledCard backgroundColor='transparent'>
      <StyledExpandedCardContent padding={'0 1rem'}>
        <CategoriesComponent
          renderedCategories={location.categories}
          renderedTitle='Service Offerings'
        />
        <CategoriesComponent
          renderedCategories={location.humanSituations}
          renderedTitle='Serving'
        />
        <StyledBorderBottom>
          <NextStepsComponent location={location}></NextStepsComponent>
        </StyledBorderBottom>
        <StyledBorderBottom style={{ paddingTop: '1rem' }}>
          <CustomStyledH3>Description</CustomStyledH3>
          <ProgramDescription text={description} />
        </StyledBorderBottom>
        <div style={{ marginTop: '1rem' }}>
          <Distance distance={distance} address={address} />
        </div>
        {numbers && (
          <div style={{ margin: '1rem 0' }}>
            <PhoneNumber numbers={numbers} />
          </div>
        )}
        {emailAddress && <EmailAddress emailAddress={emailAddress} />}{' '}
        {languages && (
          <div style={{ margin: '1rem 0' }}>
            <Languages languages={languages} />
          </div>
        )}
        {hours && <BusinessHours businessHours={hours} />}
      </StyledExpandedCardContent>
    </StyledCard>
  )
}

export default MainContent
