import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MyBenefits from 'Components/ClientPortal/MyBenefits/MyBenefits'
import MyProfile from 'Components/ClientPortal/MyProfile/MyProfile'
import ProtectedRoute from 'Components/Shared/ProtectedRoute/ProtectedRoute'
import { SCREENER_IDLE_TIMEOUT } from 'Shared/constants'
import { getClientId } from 'Shared/helpers'

const ClientPortal = () => (
  <Routes>
    {['/', '/profile', '/profile/:action/:section'].map((route, index) => (
      <Route
        path={route}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={SCREENER_IDLE_TIMEOUT}
          >
            <MyProfile />
          </ProtectedRoute>
        }
        key={index}
      />
    ))}
    <Route
      path={'/benefits'}
      element={
        <ProtectedRoute
          conditional={getClientId}
          redirectUrl={'/login'}
          timeout={SCREENER_IDLE_TIMEOUT}
        >
          <MyBenefits />
        </ProtectedRoute>
      }
    />
  </Routes>
)

export default ClientPortal
