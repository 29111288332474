import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

const headerHeight = `${theme.layout.headerHeight}`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding-top: 0.4rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${media.smaller`
    padding-bottom: 5rem;
    max-height: calc(100vh - ${headerHeight} - 8rem);
  `}
  @media (min-width:481px) and (max-width:767px) {
    padding-bottom: 0px;
    height: calc(100vh - 90px) !important;
    max-height: calc(100vh - 90px) !important;
  }
  @media (min-width: 574px) and (max-width: 767px) {
    height: calc(100vh - 120px) !important;
    max-height: calc(100vh - 120px) !important;
  }
  @media only screen and (max-width: 480px) {
    max-height: calc(100vh - 100px - 105px) !important;
    padding-bottom: 0px;
  }
`

export const StyledLocationCardContainer = styled.button<{
  $noLocationFound?: boolean
}>`
  text-align: left;
  background: unset;
  border: unset;
  width: 100%;
  ${({ $noLocationFound }) =>
    $noLocationFound &&
    css`
      padding: 1rem 1.875rem;
      line-height: 1.5;
    `}
`

export const StyledAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding-top: 0.4rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: unset;
  border: unset;
  width: 100%;
`

export const StyledLocationsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

StyledContainer.displayName = 'StyledContainer'
StyledAlertContainer.displayName = 'StyledAlertContainer'
StyledLocationsList.displayName = 'StyledLocationsList'
