import React from 'react'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { useTheme } from 'styled-components'

import Icon from '../Icon/Icon'

import { StyledAction } from './style'

type ActionToggleProps = {
  activeIconPath: React.JSX.Element | string
  activeLabel: string
  inactiveIconPath: React.JSX.Element | string
  inactiveLabel: string
  opacity?: number
  filterInMobile?: boolean
  backgroundAction?: string
  justifyContent?: string
  fillIcon?: string
  children?: React.ReactNode
  toggleLocationMobileList?: boolean
  showMenu: boolean
  onClick?: () => void
}

const ActionToggle = ({
  activeIconPath,
  activeLabel,
  inactiveIconPath,
  inactiveLabel,
  opacity,
  filterInMobile,
  children,
  backgroundAction,
  justifyContent,
  fillIcon,
  toggleLocationMobileList,
  showMenu,
  onClick,
}: ActionToggleProps) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const theme = useTheme()

  const toggleFilterMenu = () => {
    if (onClick) {
      onClick()
    }
  }

  const mobileAndTabletRenderFillColor = () => {
    const opened = '#000'
    const { alt } = theme.text

    return showMenu ? opened : alt
  }

  const desktopRenderFillColor = () => {
    const { opened, closed } = theme.menuToggle.fill

    return showMenu ? opened : closed
  }

  const renderActionIconPath = () => {
    console.log(showMenu)
    return showMenu ? activeIconPath : inactiveIconPath
  }

  const renderActionLabel = () => {
    return showMenu ? activeLabel : inactiveLabel
  }

  return (
    <MediaQuery query={MOBILE_MEDIA_QUERY}>
      {(matches) => {
        const renderFillColor = matches
          ? mobileAndTabletRenderFillColor
          : desktopRenderFillColor

        return (
          <StyledAction
            onClick={toggleFilterMenu}
            $opacity={opacity}
            $activeFilter={showMenu}
            $filterInMobile={filterInMobile}
            $background={backgroundAction}
            $justifyContent={justifyContent ?? 'center'}
            $toggleLocationMobileList={toggleLocationMobileList}
          >
            <Icon
              fill={fillIcon ?? renderFillColor()}
              scale={isMobile ? '1.8rem' : '1.5rem'}
              viewBox={'0 0 24 24'}
              screenReaderLabel={renderActionLabel()}
              cursorOnHover={'pointer'}
            >
              {renderActionIconPath()}
            </Icon>
            {children}
          </StyledAction>
        )
      }}
    </MediaQuery>
  )
}

export default ActionToggle
