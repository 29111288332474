import React from 'react'
import { updateShowLocationList } from 'Reducers/uiSlice'
import { listIconPath } from 'Shared/Icons/listIconPath'
import { mapIconPath } from 'Shared/Icons/mapIconPath'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import ActionBar from '../ActionBar/ActionBar'
import ActionToggle from '../ActionToggle/ActionToggle'
import Surface from '../Surface/Surface'

const SecondaryActionBar = () => {
  const { showFilterMenu, showLocationList, showMainMenu } = useAppSelector(
    (state) => state.ui
  )
  const dispatch = useAppDispatch()
  const pageName = showLocationList ? 'LocationsList' : 'LocationsMap'

  if (showFilterMenu || showMainMenu) return null

  return (
    <Surface
      opacity={'1'}
      elevation={1}
      position={'fixed'}
      bottom={'6rem'}
      left={0}
      right={0}
      width={'30%'}
      tabletWidth={'16%'}
      background={'unset'}
      boxShadow={'none'}
      margin={'auto'}
      minHeight={'60px'}
      pageName={pageName}
    >
      <ActionBar
        borderRadius={'50px'}
        backgroundColor={'#000'}
        padding={'0.425rem 1rem'}
      >
        <ActionToggle
          fillIcon={'#fff'}
          justifyContent={'space-between'}
          opacity={1}
          activeIconPath={mapIconPath}
          activeLabel={'Map'}
          inactiveIconPath={listIconPath}
          inactiveLabel={'List'}
          backgroundAction={'unset'}
          toggleLocationMobileList={true}
          showMenu={showFilterMenu}
          onClick={() => {
            if (showLocationList) {
              dispatch(updateShowLocationList(false))
            } else {
              dispatch(updateShowLocationList(true))
            }
          }}
        >
          {showLocationList ? 'Map' : 'List'}
        </ActionToggle>
      </ActionBar>
    </Surface>
  )
}

export default SecondaryActionBar
