import React, { lazy, useEffect } from 'react'
import { Navigate, Route, useLocation } from 'react-router-dom'
import { PartnerLocationModalContext } from 'Components/PartnerLocationModal/PartnerLocationModal'
import Fallback from 'Components/Shared/Fallback/Fallback'
import ProtectedRoute from 'Components/Shared/ProtectedRoute/ProtectedRoute'
import SentryRoutes from 'Components/Shared/SentryRoutes/SentryRoutes'
import BenefitsRecieved from 'Pages/BenefitsRecieved/BenefitsRecieved'
import ClientAuthPage from 'Pages/ClientAuth/ClientAuth'
import ClientPortal from 'Pages/ClientPortal/ClientPortal'
import CustomQuestions from 'Pages/CustomQuestions/CustomQuestions'
import DocumentsChecklistPage from 'Pages/DocumentsChecklist/DocumentsChecklistPage'
import { titleMap } from 'Pages/helpers'
import InactiveCaseNotifications from 'Pages/InactiveCaseNotifications/InactiveCaseNotifications'
import LandingPage from 'Pages/LandingPage/LandingPage'
import LocationDetail from 'Pages/LocationDetail/LocationDetail'
import Locations from 'Pages/Locations/Locations'
import Login from 'Pages/Login/Login'
import Logout from 'Pages/Logout/Logout'
import Maintenance from 'Pages/Maintenance/Maintenance'
import NextSteps from 'Pages/NextSteps/NextStepsPage'
import OutcomePage from 'Pages/Outcome/OutcomePage'
import PageNotFound from 'Pages/PageNotFound/PageNotFound'
import PreScreener from 'Pages/PreScreener/PreScreener'
import PreScreenerProvider from 'Pages/PreScreener/PreScreenerProvider'
import Results from 'Pages/Results/Results'
import Review from 'Pages/Review/Review'
import Screener from 'Pages/Screener/Screener'
import ScreenerCompleted from 'Pages/ScreenerCompleted/ScreenerCompleted'
import Unsubscribe from 'Pages/Unsubscribe/Unsubscribe'
import ZipCodeForm from 'Pages/ZipCodeVerification/ZipCodeVerification'
import { SCREENER_IDLE_TIMEOUT } from 'Shared/constants'
import { getClientId } from 'Shared/helpers'

const CaseManagement = lazy(() => import('Pages/CaseManagement/CaseManagement'))

const PageRouter = () => {
  const curLoc = useLocation()

  useEffect(() => {
    const curTitle = titleMap.find((item) =>
      curLoc.pathname.includes(item.path)
    )
    curTitle && curTitle.title
      ? (document.title = curTitle.title)
      : (document.title =
          'Single Stop™ - Your Single Stop in finding benefits & resources.')
  }, [curLoc.pathname])

  return (
    <SentryRoutes>
      <Route path={'/zip-code-validation'} element={<ZipCodeForm />} />
      <Route
        path='/locations'
        element={<Navigate to='/locations/list' replace />}
      />
      <Route path={'/locations/list'} element={<Locations />}>
        <Route path={':locationId'} element={<LocationDetail />} />
      </Route>
      <Route
        path={'/registration'}
        element={
          <PreScreenerProvider>
            <PreScreener />
          </PreScreenerProvider>
        }
      />
      <Route
        path={'/:organization/:location/registration'}
        element={
          <PreScreenerProvider>
            <PreScreener />
          </PreScreenerProvider>
        }
      />
      <Route
        path={'/questions'}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={SCREENER_IDLE_TIMEOUT}
          >
            <CustomQuestions />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/screening'}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={SCREENER_IDLE_TIMEOUT}
          >
            <Screener />
          </ProtectedRoute>
        }
      />

      <Route
        path={'/benefits-received'}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={SCREENER_IDLE_TIMEOUT}
          >
            <BenefitsRecieved />
          </ProtectedRoute>
        }
      />
      <Route path={'/completed-screener'} element={<ScreenerCompleted />} />

      <Route
        path={'/eligibility'}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={SCREENER_IDLE_TIMEOUT}
          >
            <Results section={'benefits'} />
          </ProtectedRoute>
        }
      />

      <Route
        path={'/documents-checklist'}
        element={<DocumentsChecklistPage />}
      />

      <Route
        path={'/next-steps'}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={SCREENER_IDLE_TIMEOUT}
          >
            <NextSteps />
          </ProtectedRoute>
        }
      />

      <Route path={'/outcome/:id/*'} element={<OutcomePage />} />

      <Route
        path={'/inactive-cases/:id/*'}
        element={<InactiveCaseNotifications />}
      />

      <Route
        path={'/case-management/*'}
        element={
          <Fallback>
            <CaseManagement />
          </Fallback>
        }
      />
      <Route
        path={'/account/*'}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={SCREENER_IDLE_TIMEOUT}
          >
            <ClientPortal />
          </ProtectedRoute>
        }
      />
      <Route path={'/login'} element={<Login />} />
      <Route path={'/authenticate'} element={<ClientAuthPage />} />
      <Route path={'/logout'} element={<Logout />} />
      {['/review', '/review/:action', '/review/:action/:section'].map(
        (path, index) => (
          <Route
            key={index}
            path={path}
            element={
              <ProtectedRoute
                conditional={getClientId}
                redirectUrl={'/login'}
                timeout={SCREENER_IDLE_TIMEOUT}
              >
                <Review />
              </ProtectedRoute>
            }
          />
        )
      )}

      {['/', '/:organization/:location', '/:organization'].map(
        (path, index) => (
          <Route
            key={index}
            path={path}
            element={<LandingPage modalContext={PartnerLocationModalContext} />}
          />
        )
      )}
      <Route path={'/maintenance/maintenance'} element={<Maintenance />} />
      <Route path={'/unsubscribe'} element={<Unsubscribe />} />
      <Route element={<PageNotFound />} />
    </SentryRoutes>
  )
}

export default PageRouter
