import React from 'react'
import { Hour } from 'Components/LocalResources/FindHelpResource'
import _get from 'lodash.get'
import { timeIconPath } from 'Shared/Icons/LocationInfo/paths'

import LocationInfo from '../LocationInfo/LocationInfo'

interface TodaysClosingTimeProps {
  businessHours: Hour[]
  alignitems?: string
}

const TodaysClosingTime = ({
  businessHours,
  alignitems,
}: TodaysClosingTimeProps) => {
  const today = new Date().toLocaleString('us', { weekday: 'long' })
  const closingTime = _get(
    businessHours.filter((item) => item.day === today.toLowerCase()).pop(),
    'closingTime'
  )
  return closingTime ? (
    <div style={{ marginTop: '0.875rem' }}>
      <LocationInfo
        alignitems={alignitems}
        content={`Open until ${closingTime} Today`}
        screenReaderLabel={'Open or Close'}
        icon={timeIconPath}
      />
    </div>
  ) : null
}

export default TodaysClosingTime
