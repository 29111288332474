import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@apollo/client'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ME } from 'Components/Auth/Hooks/gql'
import { StyledSkipVerificationButton } from 'Components/Auth/style'
import useStep from 'Components/PreScreener/Hooks/useStep'
import {
  ReverseCol,
  StyledForm,
  StyledMainHeading,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
  StyledParentPreScreenerButton,
  StyledSubHeading,
} from 'Components/Screener/style'
import Icon from 'Components/Shared/Icon/Icon'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import formatters from 'Components/Shared/ReduxForm/formatters/formatters'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import TextField from 'Components/Shared/ReduxForm/InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import validators from 'Components/Shared/ReduxForm/validators/validators'
import { PreScreenerContext } from 'Pages/PreScreener/PreScreenerProvider'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { setFocusOnFirstError } from 'Shared/helpers'
import backIconPath from 'Shared/Icons/backIconPath'
import { white } from 'Shared/Theme/index'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { RootState } from 'Store'
import { useAppSelector } from 'Store/hooks'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import { StyledLink, StyledWrapper } from './style'

interface FormData {
  emailOrPhone: string
}

const PreScreenerEmailOrPhone: React.FC<InjectedFormProps<FormData>> = (
  props
) => {
  const { handleSubmit, error } = props
  const focusRef = useRef<HTMLDivElement | null>(null)
  const { data } = useQuery(ME)
  const verificationSkipped = useAppSelector(
    (state) => state.preScreener?.verificationSkipped
  )
  const { handleUpdateStep } = useStep()

  useEffect(() => {
    setFocusToMainHeading()
  }, [])

  const setFocusToMainHeading = () => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }
  const context = useContext(PreScreenerContext)

  const setPreScreenerState = context?.setPreScreenerState

  const isACaseManager = data && data?.me?.isACaseManager

  const showLoginLink = useSelector(
    (state: RootState) => state.ui.showLoginLink
  )

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  const StyledSkipVerificationButtonComp = useMemo(() => {
    return () => {
      return (
        <StyledSkipVerificationButton
          onClick={(evt: React.MouseEvent<HTMLElement>) => {
            // prevent form from submitting
            evt.preventDefault()
            if (verificationSkipped) {
              handleUpdateStep(6)
            } else {
              setPreScreenerState({ modal: 'SKIP_VERIFY' })
            }
          }}
        >
          Skip Verification
          <Icon
            position={'absolute'}
            right={'1rem'}
            top={'1.4rem'}
            fill={'#0fae80'}
            margin={'0'}
            scale={'1.05rem'}
            style={{ transform: 'rotate(180deg)' }}
            viewBox={'0 0 24 24'}
            cursorOnHover={'pointer'}
          >
            {backIconPath}
          </Icon>
        </StyledSkipVerificationButton>
      )
    }
  }, [])

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md={7}>
          <StyledForm padding={'2rem 0 0'} onSubmit={handleSubmit} noValidate>
            <StyledMainHeading id='mainContent' tabIndex={-1} ref={focusRef}>
              Great! What is the best way to contact you?
            </StyledMainHeading>
            <StyledSubHeading>
              We will send a verification code to your preferred method of
              communication. Data rates may apply.
            </StyledSubHeading>
            <StyledParagraph>
              This helps us confirm your identity and secure your account. You
              can also use this email or phone number to log back in at any
              time.
            </StyledParagraph>
            <StyledHouseholdMembersListItem margin='.6rem 0 2.5rem'>
              <MuiThemeProvider theme={ssTheme}>
                <StyledHouseholdMembersList>
                  <Row>
                    <Col lg={12}>
                      <StyledMuiTextFieldWrapper
                        width='100%'
                        marginRight='auto'
                        marginLeft='auto'
                        mbottom='0'
                      >
                        <Field
                          props={{
                            ariaLabel: 'emailOrPhone',
                            dataTestId: 'emailOrPhone',
                          }}
                          name='emailOrPhone'
                          type='text'
                          component={TextField}
                          validate={validators.emailOrPhone}
                          label='Email or Mobile Phone Number'
                          normalize={formatters.lowercase}
                          disabled={verificationSkipped}
                        />
                        <FormSubmissionError error={error} />
                        {showLoginLink && (
                          <StyledLink to='/login'>
                            Log in to my account
                          </StyledLink>
                        )}
                      </StyledMuiTextFieldWrapper>
                    </Col>
                  </Row>
                </StyledHouseholdMembersList>
              </MuiThemeProvider>
            </StyledHouseholdMembersListItem>

            <StyledWrapper>
              {!verificationSkipped && (
                <>
                  {isMobile ? (
                    <StyledParentButtonsActionsInMobile>
                      <ReverseCol>
                        <StyledParentPreScreenerButton
                          width='100%'
                          $isOnlyContinue={true}
                        >
                          <ColDynamicCtaButton
                            width={'100%'}
                            type='submit'
                            data-testid={'continue-button'}
                          >
                            Continue
                            <Icon
                              position={'absolute'}
                              right={'1.375rem'}
                              fill={white}
                              margin={'0'}
                              scale={'1.5rem'}
                              style={{ transform: 'rotate(180deg)' }}
                              viewBox={'0 0 24 24'}
                              cursorOnHover={'pointer'}
                            >
                              {backIconPath}
                            </Icon>
                          </ColDynamicCtaButton>
                        </StyledParentPreScreenerButton>
                      </ReverseCol>
                    </StyledParentButtonsActionsInMobile>
                  ) : (
                    <ColDynamicCtaButton
                      width={isACaseManager ? '49%' : '100%'}
                      type='submit'
                      data-testid={'continue-button'}
                      height='3.8rem'
                    >
                      Continue
                      <Icon
                        position={'absolute'}
                        right={'1.375rem'}
                        fill={white}
                        margin={'0'}
                        scale={'1.5rem'}
                        style={{ transform: 'rotate(180deg)' }}
                        viewBox={'0 0 24 24'}
                        cursorOnHover={'pointer'}
                      >
                        {backIconPath}
                      </Icon>
                    </ColDynamicCtaButton>
                  )}
                </>
              )}
              {isACaseManager && (
                <>
                  {isMobile ? (
                    <StyledParentButtonsActionsInMobile>
                      <ReverseCol>
                        <StyledParentPreScreenerButton
                          width='100%'
                          $isOnlyContinue={true}
                        >
                          <StyledSkipVerificationButtonComp />
                        </StyledParentPreScreenerButton>
                      </ReverseCol>
                    </StyledParentButtonsActionsInMobile>
                  ) : (
                    <StyledSkipVerificationButtonComp />
                  )}
                </>
              )}
            </StyledWrapper>
          </StyledForm>
        </Col>
      </Row>
      <style>{`
        button[data-testid='continue-button'] {
          @media only screen and (max-width: 480px) {
            bottom: 45px;
            box-shadow: none !important;
          }
        }
      `}</style>
    </Container>
  )
}

export default reduxForm<FormData>({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmitFail: setFocusOnFirstError,
})(PreScreenerEmailOrPhone)
