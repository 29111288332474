import React from 'react'
import { useLocation } from 'react-router-dom'

import { CustomTabItem, TabsWrapper } from './clientcustomstyle'

function ClientPortalTabs(props: SidebarProps) {
  const location = useLocation()
  return (
    <TabsWrapper>
      <CustomTabItem
        to={'/account/profile'}
        $isClientPortal={true}
        className={
          location.pathname.includes('/account/profile') ? 'activeTab' : ''
        }
      >
        My Profile
      </CustomTabItem>
      <CustomTabItem
        to={'/account/benefits'}
        $isClientPortal={true}
        className={
          location.pathname.includes('/account/benefits') ? 'activeTab' : ''
        }
      >
        My Benefits
      </CustomTabItem>
    </TabsWrapper>
  )
}

export default ClientPortalTabs
