import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import {
  ReverseCol,
  StyledForm,
  StyledMainHeading,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
  StyledParentPreScreenerButton,
} from 'Components/Screener/style'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import TextField from 'Components/Shared/ReduxForm/InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { Field, reduxForm } from 'redux-form'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { setFocusOnFirstError } from 'Shared/helpers'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import { validName } from './helpers'
import { ResetFieldset } from './style'

const PreScreenerName = (props) => {
  const { handleSubmit } = props

  const inputStyle = {
    width: '50%',
  }

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md='7'>
          <StyledForm padding={'2rem 0 0'} onSubmit={handleSubmit}>
            <StyledMainHeading id='mainContent' tabIndex={-1}>
              It's great to meet you!
            </StyledMainHeading>
            <ResetFieldset>
              <legend>
                <StyledParagraph $isHeading={true}>
                  How should we address you?
                </StyledParagraph>
              </legend>
              <StyledHouseholdMembersListItem margin='.6rem 0 2.5rem'>
                <MuiThemeProvider theme={ssTheme}>
                  <StyledHouseholdMembersList>
                    <Row>
                      <Col lg='6'>
                        <StyledMuiTextFieldWrapper>
                          <Field
                            style={inputStyle}
                            name='first_name'
                            type='text'
                            component={TextField}
                            label='First Name'
                            autoComplete='given-name'
                            charLimit={250}
                          />
                        </StyledMuiTextFieldWrapper>
                      </Col>
                      <Col lg='6'>
                        <StyledMuiTextFieldWrapper>
                          <Field
                            name='last_name'
                            type='text'
                            component={TextField}
                            label='Last Name'
                            autoComplete='family-name'
                            charLimit={250}
                          />
                        </StyledMuiTextFieldWrapper>
                      </Col>
                      <Col lg='12'>
                        <StyledMuiTextFieldWrapper mbottom='0' fullWid>
                          <Field
                            name='nick_name'
                            type='text'
                            component={TextField}
                            label='Preferred Name'
                            autoComplete='preferred-name'
                            charLimit={250}
                          />
                        </StyledMuiTextFieldWrapper>
                      </Col>
                    </Row>
                  </StyledHouseholdMembersList>
                </MuiThemeProvider>
              </StyledHouseholdMembersListItem>
            </ResetFieldset>
            {isMobile ? (
              <StyledParentButtonsActionsInMobile>
                <ReverseCol>
                  <StyledParentPreScreenerButton
                    width='100%'
                    $isOnlyContinue={true}
                  >
                    <ColDynamicCtaButton
                      lgWidth='90%'
                      type='submit'
                      smallerMargin='0'
                    ></ColDynamicCtaButton>
                  </StyledParentPreScreenerButton>
                </ReverseCol>
              </StyledParentButtonsActionsInMobile>
            ) : (
              <Row>
                <Col lg='8'>
                  <StyledParentPreScreenerButton $isOnlyContinue={true}>
                    <ColDynamicCtaButton
                      lgWidth='90%'
                      type='submit'
                      smallerMargin='0'
                    ></ColDynamicCtaButton>
                  </StyledParentPreScreenerButton>
                </Col>
              </Row>
            )}
          </StyledForm>
        </Col>
      </Row>
    </Container>
  )
}

export default reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validName,
  onSubmitFail: setFocusOnFirstError,
})(PreScreenerName)
