import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'
import { StyledParentReportMenu } from 'Components/CaseManagement/ClientRecords/FilterColumn/style'
import MainMenuButton from 'Components/CaseManagement/Reporting/ReportingSidebar/MainMenuButton/MainMenuButton'
import sidebarMenuArray from 'Components/CaseManagement/Reporting/ReportingSidebar/sidebarMenuArray'
import useActiveLink from 'Components/Shared/Hooks/useActiveLink'
import useIsFeatureToggleOn from 'Components/Shared/Hooks/useIsFeatureToggleOn'
import useMenuItemsData, {
  getClientSummaryRoute,
} from 'Components/Shared/Hooks/useMenuItemsData'
import MenuItem from 'Components/Shared/MenuItem/MenuItem'
import TranslationWidget from 'Components/Shared/TranslationWidget/TranslationWidget'
import { toggleMainMenu } from 'Reducers/uiSlice'
import {
  CUSTOM_MOBILE_TABLET_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import { clientLocationId, getCaseManagerId, getClientId } from 'Shared/helpers'
import { isCaseManagementPage } from 'Shared/helpers'

import {
  StyledList,
  StyledListItem,
  StyledMenu,
  StyledMenuParent,
} from './style'

const Menu = () => {
  const dispatch = useDispatch()
  const { isActiveLink } = useActiveLink()
  const {
    caseManagementTopNavMenuData,
    accountData,
    logoutData,
    fullMenuData,
    clientPortalData,
  } = useMenuItemsData()

  const { showMainMenu } = useSelector((state) => {
    state.ui.showMainMenu
      ? document.body.classList.add('menu-open')
      : document.body.classList.remove('menu-open')
    return state.ui
  })

  const handleToggleMainMenu = () => dispatch(toggleMainMenu())

  const isFeatureToggleOn = useIsFeatureToggleOn()
  const isClientPortal = isFeatureToggleOn('client_portal')
  const isScreenerCompleted = useSelector(
    (state) => state.client.screenerCompleted
  )

  const menuItems = (items) => {
    let menuOptions = items
    if (isCaseManagementPage()) {
      menuOptions = [...menuOptions]
    } else if (getClientId() && getCaseManagerId()) {
      menuOptions = [
        getClientSummaryRoute(clientLocationId()),
        ...caseManagementTopNavMenuData,
        ...logoutData,
      ]
    } else if (
      getCaseManagerId() &&
      !getClientId() &&
      !isCaseManagementPage()
    ) {
      menuOptions = [...caseManagementTopNavMenuData, ...logoutData]
    } else if (!getClientId() && !getCaseManagerId()) {
      menuOptions = [...menuOptions, ...accountData]
    } else if (
      !getCaseManagerId() &&
      getClientId() &&
      isScreenerCompleted &&
      isClientPortal
    ) {
      menuOptions = [...clientPortalData, ...menuOptions, ...logoutData]
    } else {
      menuOptions = [...menuOptions, ...logoutData]
    }

    return menuOptions.map((props, index) => {
      let handleMenuItemClick =
        props.onClick && props.path !== '/login'
          ? props.onClick
          : handleToggleMainMenu

      return (
        <StyledListItem>
          <MenuItem
            className='main-menu__item'
            {...props}
            key={index}
            activelink={isActiveLink(props)}
            handleClick={handleMenuItemClick}
          />
        </StyledListItem>
      )
    })
  }

  const isCustomMobileTablet = useMediaQuery({
    query: CUSTOM_MOBILE_TABLET_MEDIA_QUERY,
  })

  const render = () => {
    if (showMainMenu) {
      return (
        <MediaQuery query={MOBILE_AND_TABLET_MEDIA_QUERY}>
          <StyledMenuParent>
            <StyledMenu className={isCaseManagementPage() ? '' : 'main-menu'}>
              {isCaseManagementPage() ? (
                <>
                  {menuItems(caseManagementTopNavMenuData)}
                  {isCustomMobileTablet && (
                    <StyledParentReportMenu>
                      {sidebarMenuArray.map((button, i) => (
                        <MainMenuButton
                          {...button}
                          key={i}
                          handleClick={handleToggleMainMenu}
                        />
                      ))}
                      <TranslationWidget insideMenu={true} />
                    </StyledParentReportMenu>
                  )}
                </>
              ) : (
                <StyledList>
                  {menuItems(fullMenuData)}
                  <StyledListItem>
                    <TranslationWidget insideMenu={true} />
                  </StyledListItem>
                </StyledList>
              )}
            </StyledMenu>
          </StyledMenuParent>
        </MediaQuery>
      )
    } else {
      return null
    }
  }

  return render()
}

export default Menu
