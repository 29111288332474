import React from 'react'
import MediaQuery from 'react-responsive'
import { updateFilterLevel } from 'Actions/filters'
import ActionToggle from 'Components/Shared/ActionToggle/ActionToggle'
import Icon from 'Components/Shared/Icon/Icon'
import { handleToggleFilterMenu } from 'Reducers/uiSlice'
import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import {
  categoriesArrowBackIconPath,
  categoriesBlackIconPath,
  categoriesCloseIconPath,
  categoriesWhiteIconPath,
} from 'Shared/Icons/CategoryIcons/CategoriesFilterIconsPath'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import CategoryFilterMegaMenu from '../CategoryFilter/CategoryFilterMegaMenu'

import { StyledSubHeading } from './style'
// import DistanceFilter from '../DistanceFilter/container'
import {
  StyledFilterBackLevelButton,
  StyledFilterMenu,
  StyledHeaderFilter,
  StyledParentFilter,
} from './style'

const FilterMenu = () => {
  const dispatch = useAppDispatch()
  const { showFilterMenu } = useAppSelector((state) => state.ui)

  const toggleFilterMenu = () => {
    dispatch(handleToggleFilterMenu())
  }

  const { currentFilterLevel, topLevelLabel, secondLevelLabel } =
    useAppSelector((state) => state.filters?.categoriesFilter)

  const handleFilterBackLevel = () => {
    if (currentFilterLevel === 'second') {
      dispatch(updateFilterLevel('first'))
    } else {
      dispatch(updateFilterLevel('second'))
    }
  }

  if (showFilterMenu) {
    return (
      <>
        <MediaQuery query={MOBILE_AND_TABLET_MEDIA_QUERY}>
          <StyledParentFilter className='categories-filter__wrapper'>
            <StyledHeaderFilter>
              {currentFilterLevel !== 'first' && (
                <StyledFilterBackLevelButton onClick={handleFilterBackLevel}>
                  <Icon
                    fill={'none'}
                    scale={'1.875rem'}
                    viewBox={'0 0 24 24'}
                    margin={'0 .5rem 0 0'}
                    screenReaderLabel={'Categories Filter'}
                  >
                    {categoriesArrowBackIconPath}
                  </Icon>
                </StyledFilterBackLevelButton>
              )}
              <StyledSubHeading className='categories-filter--title'>
                {currentFilterLevel === 'first' ? (
                  <Icon
                    fill={'none'}
                    scale={'1.25rem'}
                    viewBox={'0 0 24 24'}
                    margin={'0 .5rem 0 0'}
                    screenReaderLabel={'Categories Filter'}
                  >
                    {categoriesBlackIconPath}
                  </Icon>
                ) : (
                  ''
                )}
                {currentFilterLevel === 'second' ? topLevelLabel : ''}
                {currentFilterLevel === 'all' ? secondLevelLabel : ''}
                {currentFilterLevel === 'first' ? 'Categories' : ''}
              </StyledSubHeading>

              <ActionToggle
                filterInMobile
                activeIconPath={categoriesCloseIconPath}
                activeLabel={'filters'}
                onClick={toggleFilterMenu}
                inactiveIconPath={categoriesWhiteIconPath}
                fillIcon={'none'}
                inactiveLabel={'filters'}
                showMenu={showFilterMenu}
              />
            </StyledHeaderFilter>
            <StyledFilterMenu className='categories-filter--menu'>
              {/* <DistanceFilter callback={handleFilterChange} /> */}
              <CategoryFilterMegaMenu showResults={toggleFilterMenu} />
            </StyledFilterMenu>
          </StyledParentFilter>
        </MediaQuery>

        <MediaQuery query={DESKTOP_MEDIA_QUERY}>
          <StyledFilterMenu>
            {/* <StyledFilterTitle>Search Filters</StyledFilterTitle>
            <DistanceFilter callback={handleFilterChange} /> */}
            <CategoryFilterMegaMenu showResults={toggleFilterMenu} />
            {/* <StyledFilterBtn onClick={toggleFilterMenu}>
              Show Results
            </StyledFilterBtn> */}
          </StyledFilterMenu>
        </MediaQuery>
      </>
    )
  } else {
    return null
  }
}

export default FilterMenu
