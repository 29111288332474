import React from 'react'

import { StyledLocationCardContainer } from './style'

const NoLocationsReslts = () => (
  <StyledLocationCardContainer $noLocationFound={true}>
    No programs or services found for this search. Try entering another ZIP
    code, changing the search area, or entering a new search term.
  </StyledLocationCardContainer>
)
export default NoLocationsReslts
