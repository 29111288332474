import { CSSProperties } from '@material-ui/core/styles/withStyles'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

interface StyledInputProps extends CSSProperties {
  isHidden?: boolean
  disabled?: boolean
}

export const primaryButtonProps = css<StyledInputProps>`
  display: ${({ isHidden }) => isHidden && 'none'};
  background: ${theme.palette.primary.dark};
  color: ${theme.palette.color.white};
  text-shadow: 0 0 5px ${theme.palette.color.black};

  &:hover {
    background: ${({ disabled }) =>
      disabled ? theme.palette.primary.dark : theme.palette.primary.darken5};
    box-shadow: 0 3px 5px -1px #2e2e2e;
  }
`

interface ButtonProps extends CSSProperties {
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  disabled?: boolean
  isHidden?: boolean
  md?: boolean
  lg?: boolean
  mdMinWidth?: string
  mdMinHeight?: string
  mdFontSize?: string
  radius?: string
  textdecoration?: string
  customBackgroundSecondaryButton?: string
  white?: boolean
  black?: boolean
  margin?: string
  modalMobile?: boolean
  featuretext?: boolean
  custommobiletablet?: boolean
  custommargin?: string
  custompadding?: string
  customminheight?: string
  authHeader?: boolean
}

export const Button = styled.button<ButtonProps>`
  background: ${(props) => props.background || 'transparent'};
  color: ${theme.palette.text.default};
  min-width: 64px;
  text-align: center;
  text-transform: capitalize;
  padding: ${(props) => props.padding || '0.7em 1.8em 0.8em'};
  border: none;
  border-radius: ${(props) => props.radius || '7px'};
  font-size: 14px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
  text-decoration: ${(props) =>
    props.textdecoration ? props.textdecoration : 'none'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  margin: ${(props) => props.margin || '0'};
  width: ${(props) => props.width || 'auto'};
  min-height: ${(props) => props.minHeight || 'auto'};
  display: ${(props) => props.display || 'inline-flex'};
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${({ disabled }) =>
      disabled ? theme.palette.primary.dark : theme.palette.primary.darken5};
    color: ${({ disabled }) => !disabled && theme.palette.color.white};
  }
  &:focus {
    outline: unset;
    box-shadow:
      0 3px 5px -1px #2e2e2e,
      0 6px 10px 0 #2e2e2e,
      0 1px 18px 0 #2e2e2e;
  }
  @media only screen and (max-width: 480px) {
    margin: 0.375rem;
  }
  ${({ primary }) => primary && primaryButtonProps};
  ${({ secondary }) =>
    secondary &&
    css<ButtonProps>`
      background-color: ${(props) =>
        props.customBackgroundSecondaryButton
          ? props.customBackgroundSecondaryButton
          : theme.palette.secondary.default};
      color: ${theme.palette.text.default};
      &:hover {
        background: ${theme.palette.secondary.darken10};
        color: ${theme.palette.text.default};
      }
      &:disabled {
        color: inherit;
      }
    `};
  ${({ white }) =>
    white &&
    css`
      background: ${theme.palette.color.white};
    `};
  ${({ black }) =>
    black &&
    css<ButtonProps>`
      background: ${theme.palette.color.black};
      color: ${theme.palette.color.white};
      margin: ${(props) => props.margin};
    `};
  ${({ md }) =>
    md &&
    css<ButtonProps>`
      min-width: ${(props) =>
        props.mdMinWidth ? props.mdMinWidth : '13.125rem'};
      min-height: ${(props) =>
        props.mdMinHeight ? props.mdMinHeight : '3.75rem'};
      padding: ${(props) => props.padding};
      font-size: ${(props) =>
        props.mdFontSize ? props.mdFontSize : '1.125rem'};
    `};
  ${({ lg }) =>
    lg &&
    css<ButtonProps>`
      min-width: 280px;
      padding: 0.9em 1.4em 1em;
      font-size: 1.125rem;
      border-radius: ${(props) => (props.radius ? '7px' : '0 7px 7px 0')};
      @media only screen and (max-width: 480px) {
        min-width: 8.125rem;
        margin: 0;
        font-size: 0.8rem;
      }
    `};
  ${({ modalMobile }) =>
    modalMobile &&
    css`
      @media only screen and (max-width: 480px) {
        min-width: auto;
        width: 50%;
        margin: 0.1875rem;
        height: 2.375rem;
        min-height: unset;
        padding: 0;
      }
    `}
  ${({ featuretext }) =>
    featuretext &&
    css`
      @media only screen and (max-width: 850px) {
        width: 100%;
        min-height: 4.5rem;
        font-size: 1.325rem;
        margin: 0.5rem 0;
      }
      @media only screen and (min-width: 850.1px) and (max-width: 992px) {
        width: 70%;
        min-height: 4.5rem;
        font-size: 1.325rem;
        margin: 0.5rem 0;
      }
    `}
    ${({ custommobiletablet }) =>
    custommobiletablet &&
    css<ButtonProps>`
      @media only screen and (max-width: 800px) {
        margin: ${(props) => props.custommargin};
        padding: ${(props) => props.custompadding};
        min-height: ${(props) => props.customminheight};
        &:hover {
          background: unset;
          color: ${theme.palette.color.black};
        }
      }
    `}
    ${({ authHeader }) =>
    authHeader &&
    css`
      font-size: 0.9rem;
    `};
`
