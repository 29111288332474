import React, { useState } from 'react'
import { ArrowSpan } from 'Components/Shared/Dialog/style'
import { timeIconPath } from 'Shared/Icons/LocationInfo/paths'

import LocationInfo from '../LocationInfo/LocationInfo'

import Day from './Day'
import { StyledBusinessHoursButton, StyledDays } from './style'

const BusinessHours = (props) => {
  const { businessHours } = props
  const todayEnabled = false
  const [showHours, toggleHours] = useState(true)

  const renderToday = () => {
    const today = new Date()
      .toLocaleString('default', { weekday: 'long' })
      .toLowerCase()
    const todayBusinessHours = businessHours.find((day) => day.day === today)
    const isOpenToday =
      todayBusinessHours.openingTime !== null &&
      todayBusinessHours.closingTime !== null

    return (
      <StyledBusinessHoursButton
        onClick={() => toggleHours(!showHours)}
        onKeyDown={() => toggleHours(!showHours)}
        role='button'
      >
        {!todayEnabled && (
          <>
            <p
              className='text'
              style={{ marginTop: `${showHours ? '2px' : '-2px'}` }}
            >{`${isOpenToday ? 'Open' : 'Closed'} now`}</p>
            {showHours ? (
              <ArrowSpan
                right={'-40px'}
                afterTop={'24%'}
                afterLeft={'0'}
                toggleRotate
                screenReaderLabel={'Open or Close'}
              />
            ) : (
              <ArrowSpan
                right={'-40px'}
                afterLeft={'0'}
                screenReaderLabel={'Open or Close'}
              />
            )}
          </>
        )}
      </StyledBusinessHoursButton>
    )
  }

  const days = (businessHours) => (
    <StyledDays>
      <tbody>
        {businessHours.map((day) => (
          <Day {...day} key={day.id} />
        ))}
      </tbody>
    </StyledDays>
  )

  const content = (
    <div>
      {renderToday()}
      {showHours ? days(businessHours) : null}
    </div>
  )

  if (businessHours?.length > 0) {
    return (
      <LocationInfo
        content={content}
        screenReaderLabel={'Business Hours'}
        icon={timeIconPath}
      />
    )
  } else {
    return null
  }
}

export default BusinessHours
