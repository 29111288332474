import React from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledBorderBottom } from 'Components/LocalResources/LocationCard/style'
import Icon from 'Components/Shared/Icon/Icon'
import { replaceApostrophes } from 'Shared/helpers'
import { SrOnly } from 'Shared/Theme/utilities.styles'

import {
  CustomStyledProgramName,
  CustomStyledProviderName,
  StyledCloseButton,
  StyledLocationDetail,
  StyledLocationTitleContainer,
} from './style'

interface TitleProps {
  program_name: string
  name: string
  provider_name: string
  preferredPartner: React.ReactNode | null
}
const Title = ({
  program_name,
  name,
  provider_name,
  preferredPartner,
}: TitleProps) => {
  const navigate = useNavigate()

  return (
    <StyledLocationTitleContainer>
      <StyledBorderBottom>
        <StyledCloseButton
          aria-label='Close'
          data-testid='close-modal-button'
          onClick={() => navigate('/locations/list')}
        >
          <SrOnly>Close</SrOnly>
          {/* x icon */}
          <Icon
            screenReaderLabel='close'
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            className='close-icon'
          >
            {closeIcon}
          </Icon>
          {/* arrow icon */}
          <Icon
            screenReaderLabel='close'
            fill={'none'}
            viewBox={'0 0 24 24'}
            cursorOnHover={'pointer'}
            width='24'
            height='24'
            className='arrow-back-icon'
          >
            {backIconPath}
          </Icon>
        </StyledCloseButton>
        <CustomStyledProgramName>
          {replaceApostrophes(program_name)}
        </CustomStyledProgramName>
        <StyledLocationDetail> by</StyledLocationDetail>{' '}
        <CustomStyledProviderName>
          {replaceApostrophes(provider_name)}
        </CustomStyledProviderName>
        {provider_name !== name && (
          <>
            <StyledLocationDetail> &mdash; </StyledLocationDetail>
            <CustomStyledProviderName>
              {replaceApostrophes(name)}
            </CustomStyledProviderName>
          </>
        )}
        {preferredPartner}
      </StyledBorderBottom>
    </StyledLocationTitleContainer>
  )
}

export default Title

const backIconPath = (
  <>
    <path
      d='M14 18L8 12L14 6'
      stroke='#151515'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </>
)

const closeIcon = (
  <>
    <svg xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 1L1 11M1 1L11 11'
        stroke='#151515'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </>
)
