import styled, { css } from 'styled-components'

export const StyledCloseButton = styled.button`
  position: absolute;
  right: unset;
  left: 4px;
  top: 10px;
  padding: 0.25rem;
  cursor: pointer;
  background: white;
  border-radius: 99px;
  border: none;
  color: #333;
  display: flex;
  box-shadow: 5px 5px 20px rgba(174, 174, 174, 0.25);

  .close-icon,
  .arrow-back-icon {
    display: none;
    cursor: pointer;
  }

  @media only screen and (min-width: 480px) {
    left: 4px;
    top: 10px;
    .arrow-back-icon {
      display: block;
    }
  }

  @media only screen and (min-width: 1024px) {
    right: 28px;
    top: 16px;
    left: auto;
    padding: 0.5rem;

    .arrow-back-icon {
      display: none;
    }

    .close-icon {
      display: block;
    }
  }

  @media only screen and (max-width: 1024px) {
    background-color: transparent;
    color: #333;
    box-shadow: none;
    right: unset;
    top: 10px;
    left: 4px;
    border: none !important;
    outline: none !important;
    .arrow-back-icon {
      display: block;
    }

    .close-icon {
      display: none;
    }
  }
  @media only screen and (max-width: 480px) {
    .arrow-back-icon {
      display: none;
    }
  }
  transition: 0.2s all ease-in-out;
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    background-color: #cacaca;
  }
`

export const StyledLocationTitleContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  padding: 1rem 1rem 0;
`

export const CustomStyledProgramName = styled.h4`
  color: #075a41;
  text-transform: capitalize;
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  font-family: 'Noto Sans HK', sans-serif;
  margin: 0;
  padding-right: 20px;
  @media only screen and (max-width: 1024px) {
    padding: 0 25px;
    text-align: center;
    font-size: 16px;
  }
  @media only screen and (max-width: 480px) {
    text-align: unset;
    padding: 0;
  }
`

export const StyledProviderName = styled.span`
  color: #151515;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 600;
  font-family: 'Noto Sans HK', sans-serif;
  margin: 0;
`

export const StyledLocationDetail = styled.span<{
  $primary?: boolean
  $isAddress?: boolean
}>`
  color: #595959;
  font-family: 'Noto Sans HK', sans-serif;
  margin: 0;
  font-size: 14px;
  line-height: unset;
  font-weight: 450;
  margin-bottom: 0.5rem;
  ${({ $isAddress }) =>
    $isAddress &&
    css`
      font-weight: 600;
      margin-bottom: 0;
    `}
  ${({ $primary }) =>
    $primary &&
    css`
      color: #151515;
    `}
`

export const CustomStyledProviderName = styled.span`
  color: #151515;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  font-family: 'Noto Sans HK', sans-serif;
  margin: 0;
`
