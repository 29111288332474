import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import useOtp from 'Components/Auth/Hooks/useOtp'
import {
  ReverseCol,
  StyledDynamicCtaButton,
  StyledForm,
  StyledMainHeading,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
  StyledParentPreScreenerButton,
  StyledSubHeading,
} from 'Components/Screener/style'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import TextField from 'Components/Shared/ReduxForm/InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import validators from 'Components/Shared/ReduxForm/validators/validators'
import { Field, reduxForm } from 'redux-form'
import { emailOrPhone as selectEmailOrPhone } from 'Selectors'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { setFocusOnFirstError } from 'Shared/helpers'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import { parseEmailOrPhone } from '../PreScreener/helpers'

const ClientAuth = ({ error, handleSubmit }) => {
  const focusRef = useRef(null)

  const { handleRequestOtp, loading } = useOtp()

  useEffect(() => {
    setFocusToMainHeading()
    setTimeout(() => {
      document.getElementById('otp')?.focus()
    })
  }, [])

  const emailOrPhone = useSelector((state) => selectEmailOrPhone(state))

  const setFocusToMainHeading = () => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }

  const handleResendOtp = () => {
    handleRequestOtp(parseEmailOrPhone({ emailOrPhone }))
  }

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md='7'>
          <StyledForm padding={'2rem 0 0'} onSubmit={handleSubmit} noValidate>
            <StyledMainHeading id='mainContent' tabIndex='-1' ref={focusRef}>
              Verify Your Account
            </StyledMainHeading>
            <StyledParagraph>
              Please take a moment to verify your contact information. This
              helps us to confirm your identity and secure your account.
            </StyledParagraph>
            <StyledSubHeading>
              Please enter the verification code sent to:
            </StyledSubHeading>
            <StyledSubHeading>{emailOrPhone}</StyledSubHeading>
            <StyledHouseholdMembersListItem margin='.6rem 0 2.5rem'>
              <MuiThemeProvider theme={ssTheme}>
                <StyledHouseholdMembersList>
                  <Row>
                    <Col lg='12'>
                      <StyledMuiTextFieldWrapper
                        width='100%'
                        marginRight='auto'
                        marginLeft='auto'
                        mbottom='0'
                      >
                        <Field
                          name='otp'
                          type='text'
                          component={TextField}
                          label='Verification Code'
                        />
                        <FormSubmissionError error={error} />
                      </StyledMuiTextFieldWrapper>
                    </Col>
                  </Row>
                </StyledHouseholdMembersList>
              </MuiThemeProvider>
            </StyledHouseholdMembersListItem>
            {isMobile ? (
              <StyledParentButtonsActionsInMobile>
                <ReverseCol>
                  <StyledParentPreScreenerButton width='100%'>
                    <StyledDynamicCtaButton
                      width='100%'
                      $hasExtraButton
                      type='button'
                      onClick={handleResendOtp}
                      disabled={loading}
                      margin='1rem 0'
                    >
                      Resend Code
                    </StyledDynamicCtaButton>
                  </StyledParentPreScreenerButton>
                  <StyledParentPreScreenerButton width='100%'>
                    <ColDynamicCtaButton smallerMargin='0' />
                  </StyledParentPreScreenerButton>
                </ReverseCol>
              </StyledParentButtonsActionsInMobile>
            ) : (
              <StyledHouseholdMembersList textalign='center'>
                <Row>
                  <Col lg='12'>
                    <ColDynamicCtaButton />
                    <StyledDynamicCtaButton
                      width='100%'
                      $hasExtraButton
                      type='button'
                      onClick={handleResendOtp}
                      disabled={loading}
                      margin='1rem 0'
                    >
                      Resend Code
                    </StyledDynamicCtaButton>
                  </Col>
                </Row>
              </StyledHouseholdMembersList>
            )}
          </StyledForm>
        </Col>
      </Row>
    </Container>
  )
}

export default reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validators.otp,
  onSubmitFail: setFocusOnFirstError,
})(ClientAuth)
