import React, { MouseEventHandler } from 'react'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'

import { StyledButtonsContainer } from '../style'

interface ConfirmOrCancelProps {
  handleOnCancel: MouseEventHandler<HTMLButtonElement>
  handleOnConfirm: MouseEventHandler<HTMLButtonElement>
  confirmLabel: string
  confirmOnly?: boolean
  cancelLabel?: string
  confirmButtonType?: string
  isReadOnly?: boolean
  isHidden?: boolean
  submitting?: boolean
}

const ConfirmOrCancel: React.FC<ConfirmOrCancelProps> = ({
  handleOnCancel,
  handleOnConfirm,
  confirmLabel,
  confirmOnly,
  cancelLabel,
  confirmButtonType = undefined,
  isReadOnly,
  isHidden,
  submitting,
}) => {
  const fallbackLabel = isReadOnly ? 'close' : 'cancel'
  return (
    <StyledButtonsContainer>
      {!confirmOnly && (
        <Button
          mdFontSize='0.875rem'
          mdMinWidth='10.25rem'
          mdMinHeight='3rem'
          fontWeight={500}
          secondary
          md
          modalMobile
          disabled={submitting}
          onClick={handleOnCancel}
          margin={isHidden ? '0' : '0 1rem 0 0'}
          data-testid='rtl-cancel-button'
        >
          {cancelLabel || fallbackLabel}
        </Button>
      )}

      <Button
        mdFontSize='0.875rem'
        mdMinWidth='10.25rem'
        mdMinHeight='3rem'
        fontWeight={500}
        primary
        md
        modalMobile
        type={confirmButtonType}
        onClick={handleOnConfirm}
        disabled={isReadOnly || submitting}
        isHidden={isHidden}
        data-testid='rtl-confirm-button'
      >
        {confirmLabel}
      </Button>
    </StyledButtonsContainer>
  )
}

export default ConfirmOrCancel
