import React from 'react'
import { connect } from 'react-redux'
import ScreenerActions from 'Components/Screener/screenerActions'
import { FieldArray } from 'redux-form'
import { screenerSelector } from 'Selectors'

import BirthDateAndAgeRenderField from './BirthDateAndAgeRenderField'
import { useFields } from './hooks'

let customProps = {}
let label, name, validate

const BirthDateAndAge = (props) => {
  ;({
    label,
    name,
    validate,
    replacedQuestionDirectionText: customProps.text,
  } = props)
  useFields(props, {})

  return (
    <>
      <FieldArray
        name={name}
        label={label}
        component={BirthDateAndAgeRenderField}
        type={'number'}
        validate={validate}
        props={customProps}
      />
      <ScreenerActions {...props} />
    </>
  )
}

const stateToProps = (state, ownProps) => {
  return {
    backButtonClicked: state.screener.returnStep,
    fieldValues: screenerSelector(state, ownProps.name),
  }
}

export default connect(stateToProps)(BirthDateAndAge)
