import React, { useCallback } from 'react'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import CalendarDays from 'Components/Shared/CalendarDays/CalendarDays'
import CalendarMonths from 'Components/Shared/CalendarMonths/CalendarMonths'
import CalendarYears from 'Components/Shared/CalendarYears/CalendarYears'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
} from 'Components/Shared/ReduxForm/style'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { Col, Row } from 'styled-bootstrap-grid'

import FieldSelect from './FieldSelect'
import { useFieldName } from './hooks'

const BirthDateAndAgeRenderField = ({ fields, meta: { error } }) => {
  const fieldName = useFieldName(fields)

  const validateBirthMonth = useCallback(
    () => error && error.birthMonth,
    [error]
  )
  const validateBirthDay = useCallback(() => error && error.birthDay, [error])
  const validateBirthYear = useCallback(() => error && error.birthYear, [error])

  return (
    <StyledHouseholdMembersList>
      <StyledHouseholdMembersListItem margin='0 0 2.5rem'>
        <MuiThemeProvider theme={ssTheme}>
          <Row>
            <Col md='4'>
              <FieldSelect
                name={`${fieldName}.birth_month`}
                label='Month'
                validate={validateBirthMonth}
              >
                {CalendarMonths()}
              </FieldSelect>
            </Col>
            <Col md='4'>
              <FieldSelect
                name={`${fieldName}.birth_day`}
                label='Day'
                validate={validateBirthDay}
              >
                {CalendarDays()}
              </FieldSelect>
            </Col>
            <Col md='4'>
              <FieldSelect
                name={`${fieldName}.birth_year`}
                label='Year'
                validate={validateBirthYear}
              >
                {CalendarYears()}
              </FieldSelect>
            </Col>
          </Row>
        </MuiThemeProvider>
      </StyledHouseholdMembersListItem>
    </StyledHouseholdMembersList>
  )
}

export default React.memo(BirthDateAndAgeRenderField)
