import React from 'react'

import MilitaryDateSubSelector from './MilitaryDateSubSelector'

interface MilitaryDateFullSelectorProps {
  meta: { error: string }
  input: { name: string }
}
const MilitaryDateFullSelector = (props: MilitaryDateFullSelectorProps) => (
  <>
    <MilitaryDateSubSelector {...props} namePrefix='start' text='Start Date' />
    <MilitaryDateSubSelector {...props} namePrefix='end' text='End Date' />
  </>
)

export default MilitaryDateFullSelector
