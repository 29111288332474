import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSlice } from '@reduxjs/toolkit'
import { myBenefits as initialState } from 'Reducers/initialState'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

const rememberRehydrated = createAction<{ ui: typeof initialState }>(
  REMEMBER_REHYDRATED
)

export type CaseModalPayload = {
  toolkitUrl?: string
  benefitDescription: string
  openEligibilityKey: string
  benefitName: string
}

export const myBenefitsSlice = createSlice({
  name: 'myBenefits',
  initialState,
  reducers: {
    openBenefitModal: (state, action: PayloadAction<CaseModalPayload>) => {
      state.openBenefitModal = true
      state.toolkitUrl = action.payload.toolkitUrl
      state.benefitDescription = action.payload.benefitDescription
      state.benefitName = action.payload.benefitName
      state.openEligibilityKey = action.payload.openEligibilityKey
    },
    closeBenefitModal: (state) => {
      state.openBenefitModal = false
      state.toolkitUrl = ''
      state.benefitDescription = ''
      state.benefitName = ''
      state.openEligibilityKey = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rememberRehydrated, (state, action) => {
      return {
        ...state,
        ...action.payload.ui,
        rehydrated: true,
      }
    })
    builder.addCase(REMEMBER_PERSISTED, (state) => {
      state.rehydrated = false
      state.persisted = true
    })
  },
})
export const { openBenefitModal, closeBenefitModal } = myBenefitsSlice.actions
export default myBenefitsSlice.reducer
