import React, { useContext, useEffect, useRef, useState } from 'react'
import { parseQuery } from 'Actions/filters'
import { handleShareLocation } from 'Actions/geolocation'
import { DialogTitle } from 'Components/Shared/Dialog/Dialog'
import Icon from 'Components/Shared/Icon/Icon'
import { StyledInput } from 'Components/Shared/SearchInput/style'
import { handlePreventUserTypeLetters } from 'Pages/Locations/helpers'
import resourcesMap from 'Shared/Icons/resourcesMap'
import { useAppDispatch, useAppSelector } from 'Store/hooks'
import { ThemeContext } from 'styled-components'

import {
  DialogContent,
  Divider,
  OrText,
  StyledButton,
  StyledDialog,
  StyledLocationRequest,
  StyledLocationTitle,
  StyledOutlinedButton,
  StyledParagraph,
} from './style'

const LocationRequest = () => {
  const dispatch = useAppDispatch()
  const themeContext = useContext(ThemeContext)
  const inputRef = useRef<HTMLInputElement>(null)

  const { geolocationState } = useAppSelector((state) => state.geolocation)
  const [showLocationRequestModal, setShowLocationRequestModal] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [zipCodeError, setZipCodeError] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      const ModalContainer = document.querySelector('[role="dialog"]')
      if (ModalContainer) {
        ModalContainer.setAttribute('aria-modal', 'true')
        ModalContainer.setAttribute('tabindex', '-1')
      }
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 300)

    // Cleanup function to clear the timeout
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (geolocationState === 'granted') {
      setShowLocationRequestModal(false)
    }
  }, [geolocationState])
  const handleSearchByZipCode = () => {
    setShowLocationRequestModal(false)
    if (searchTerm && searchTerm.length === 5) {
      dispatch(parseQuery(searchTerm, true))
    } else {
      setZipCodeError(true)
    }
  }

  const handleSearchByLocation = () => {
    dispatch(handleShareLocation())
  }

  if (!showLocationRequestModal) return null

  return (
    <StyledLocationRequest
      data-testid='location-request-dialog'
      onTouchStartCapture={(e) => {
        e.nativeEvent.stopPropagation()
      }}
    >
      <StyledDialog
        aria-labelledby='Sharing-your-location'
        open={showLocationRequestModal}
        style={{
          zIndex: 9999999,
        }}
        PaperProps={{
          style: {
            zIndex: 999999999,
          },
        }}
      >
        <DialogContent
          style={{ textAlign: 'center', margin: '0 auto', zIndex: 99999999 }}
        >
          <div className='content-container'>
            <DialogTitle id='Sharing-your-location' style={{ padding: 0 }}>
              <StyledLocationTitle>
                Welcome to <br /> Community Resources!
              </StyledLocationTitle>
            </DialogTitle>
            <StyledParagraph style={{ fontSize: '1rem' }}>
              Enter your ZIP code to start a search.
            </StyledParagraph>
            <StyledInput
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              ref={inputRef}
              hasError={zipCodeError}
              maxLength={5}
              onKeyDown={(e) => {
                if (handlePreventUserTypeLetters(e)) {
                  if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                    if (searchTerm.length !== 5) {
                      setZipCodeError(true)
                    } else {
                      setZipCodeError(false)
                      handleSearchByZipCode()
                    }
                  }
                }
              }}
              onChange={(e) => {
                setSearchTerm(e.target.value)
              }}
              placeholder={'ZIP Code'}
            />
            {zipCodeError && (
              <p className='error-message'>Please enter a valid ZIP Code.</p>
            )}
            <StyledButton
              type='button'
              onClick={() => {
                if (searchTerm.length !== 5) {
                  setZipCodeError(true)
                  inputRef.current?.focus()
                } else {
                  setZipCodeError(false)
                  handleSearchByZipCode()
                }
              }}
            >
              Continue
            </StyledButton>
            <Divider>
              <OrText>or</OrText>
            </Divider>
            <StyledOutlinedButton
              margin={'0.6rem auto 2rem'}
              elevation={0}
              onClick={() => {
                handleSearchByLocation()
              }}
              type='button'
            >
              Share My Location
            </StyledOutlinedButton>
            {geolocationState === 'denied' && (
              <StyledParagraph
                data-testid='location-request-denied'
                style={{
                  fontSize: '1rem',
                  marginTop: '1rem',
                  marginBottom: '0',
                }}
              >
                Location sharing is turned off in your browser.
              </StyledParagraph>
            )}
            {geolocationState === 'error' && (
              <StyledParagraph
                data-testid='location-request-error'
                style={{
                  fontSize: '1rem',
                  marginTop: '1rem',
                  marginBottom: '0',
                }}
              >
                Location sharing is not supported in your browser.
              </StyledParagraph>
            )}
          </div>
          <Icon
            viewBox='0 0 375 301'
            fill={themeContext?.icon.dark}
            margin={'0 auto'}
            screenReaderLabel={'Resources Map'}
            width='100%'
            height='100%'
          >
            {resourcesMap}
          </Icon>
        </DialogContent>
      </StyledDialog>
    </StyledLocationRequest>
  )
}

export default LocationRequest
