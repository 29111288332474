import React from 'react'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import ScreenerActions from 'Components/Screener/screenerActions'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
} from 'Components/Shared/ReduxForm/style'
import { Field } from 'redux-form'
import { ssTheme } from 'Shared/Theme/ssTheme'

import MilitaryDateFullSelector from './MilitaryDateFullSelector'

const DateSelectorMilitaryFields = ({
  validate,
  FieldType = Field,
  Component = MilitaryDateFullSelector,
  ...props
}) => (
  <>
    <StyledHouseholdMembersList>
      <StyledHouseholdMembersListItem margin='0 0 1.5rem'>
        <MuiThemeProvider theme={ssTheme}>
          <FieldType
            name={props.name}
            component={Component}
            validate={validate}
          />
        </MuiThemeProvider>
      </StyledHouseholdMembersListItem>
    </StyledHouseholdMembersList>

    <ScreenerActions {...props} />
  </>
)

export default DateSelectorMilitaryFields
