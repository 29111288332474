import React from 'react'
import { Icon } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import {
  Address,
  FindHelpResource,
} from 'Components/LocalResources/FindHelpResource'
import { StyledLocationDetail } from 'Components/LocalResources/LocationCard/style'
import LocationInfo from 'Components/Shared/LocationDetails/LocationInfo/LocationInfo'
import { StyledLocationInfo } from 'Components/Shared/LocationDetails/LocationInfo/style'
import { outlineLocationIconPath } from 'Shared/Icons/LocationInfo/paths'

import {
  CustomNavLink,
  StyledCardComponent,
  StyledCardComponentHeader,
  StyledCardComponentInfo,
  StyledCardComponentInfoLocationWrapper,
  StyledInfoTime,
  StyledInfoTimeInfo,
  StyledInfoTimeStatus,
} from './style'

const NextStepsComponent = ({ location }: { location: FindHelpResource }) => {
  const {
    hours: businessHours,
    distance,
    address,
    numbers,
    coordinates,
  } = location

  type BusinessHour = {
    day: string
    openingTime: string | null
    closingTime: string | null
  }

  let today: string
  let isOpenToday
  let closingTime
  let nextOpenedDayInfo: unknown
  const TodaysClosingTime = (businessHours: BusinessHour[]) => {
    today = new Date().toLocaleString('us', { weekday: 'long' }).toLowerCase()
    const daysOfWeek = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]
    const todayIndex = daysOfWeek.indexOf(today)

    const todayInfo = businessHours.find(
      (dayInfo: { day: string; openingTime: string | null }) =>
        dayInfo.day === today
    )
    isOpenToday = todayInfo && todayInfo?.openingTime !== null
    closingTime = isOpenToday ? todayInfo?.closingTime : null

    // Here we will get next Opened Day

    for (let i = 1; i <= 7; i++) {
      const nextIndex = (todayIndex + i) % 7
      const nextDay = daysOfWeek[nextIndex]
      const nextDayInfo = businessHours.find(
        (dayInfo) => dayInfo.day === nextDay
      )

      if (nextDayInfo && nextDayInfo.openingTime !== null) {
        nextOpenedDayInfo = nextDayInfo
        return false
      }
    }
  }
  TodaysClosingTime(businessHours)

  const renderAddress = (address: Address) => {
    if (!address) {
      return null
    }

    const addressArray = []
    const { addressLine1, addressLine2, city, state, zipCode } = address

    if (addressLine1) addressArray.push(addressLine1)
    if (addressLine2) addressArray.push(addressLine2)
    if (city) addressArray.push(city)
    if (state) addressArray.push(state)
    if (zipCode) addressArray.push(zipCode)
    return addressArray.join(', ')
  }
  return (
    <>
      <StyledCardComponent>
        <StyledCardComponentHeader>Next Steps</StyledCardComponentHeader>

        <StyledCardComponentInfo>
          Go to the{' '}
          <CustomNavLink
            target='_blank'
            to={`http://www.google.com/maps/place/${coordinates.lat},${coordinates.lng}`}
          >
            nearest location
          </CustomNavLink>
          {numbers.primary && <> or call </>}
          {numbers?.primary && (
            <CustomNavLink target='_blank' to={`tel:+${numbers.primary}`}>
              {' '}
              {numbers.primary}
            </CustomNavLink>
          )}
        </StyledCardComponentInfo>

        <StyledCardComponentInfoLocationWrapper>
          <LocationInfo
            content={`${distance} miles away (serves your local area)`}
            icon={outlineLocationIconPath}
            screenReaderLabel={'Location'}
          />
          {address && (
            <LocationInfo
              $isAddress
              content={renderAddress(address)}
              icon={distance ? null : outlineLocationIconPath}
              screenReaderLabel={'Location'}
            />
          )}
        </StyledCardComponentInfoLocationWrapper>

        <div>
          <StyledLocationInfo showMore style={{ alignItems: 'center' }}>
            <Icon
              alignItems='center'
              style={{ alignItems: 'center', display: 'flex' }}
              scale={'1.4rem'}
              fill={'rgba(0,0,0,.2)'}
              viewBox={'0 0 24 24'}
              margin={'0 0.8rem 0 0'}
            >
              <AccessTimeIcon
                style={{ width: '24px', height: '24px', color: '#11AB7C' }}
              />
            </Icon>
            <StyledLocationDetail
              $primary
              style={{ width: '100%', marginBottom: '0' }}
            >
              <StyledInfoTime>
                <StyledInfoTimeStatus>
                  {isOpenToday ? 'Open' : 'Closed'}
                </StyledInfoTimeStatus>
                {isOpenToday ? (
                  <StyledInfoTimeInfo>
                    <span>Closes at {closingTime}</span>
                  </StyledInfoTimeInfo>
                ) : (
                  <>
                    <StyledInfoTimeInfo>
                      <span>
                        Opens at {nextOpenedDayInfo?.day}{' '}
                        {nextOpenedDayInfo?.openingTime}
                      </span>
                    </StyledInfoTimeInfo>
                  </>
                )}
              </StyledInfoTime>
            </StyledLocationDetail>
          </StyledLocationInfo>
        </div>
      </StyledCardComponent>
    </>
  )
}

export default NextStepsComponent
