import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'
import { Service, ServiceCategory } from '__generated__/graphql'
import { useQuery } from '@apollo/client'
import BenefitGuidesList from 'Components/NextSteps/BenefitGuidesList'
import { mapAndGroupBenefitsGuides } from 'Components/NextSteps/helpers'
import { ButtonWrapperBackground } from 'Components/NextSteps/style'
import { StyledDynamicCtaButton } from 'Components/Screener/style'
import { StyledMainHeading, StyledParagraph } from 'Components/Screener/style'
import { Dictionary } from 'lodash'
import NextStepsDefaultCopy from 'Pages/NextSteps/NextStepsDefaultCopy'
import NextStepsLoading from 'Pages/NextSteps/NextStepsLoading'
import {
  Benefitwrapper,
  StyledCustomContent,
  StyledFlexWrapper,
  StyledWrapper,
} from 'Pages/NextSteps/style'
import {
  MOBILE_AND_TABLET_MEDIA_QUERY,
  MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY,
} from 'Shared/constants'
import { singleStopGreen } from 'Shared/Theme'
import theme from 'Shared/Theme/ssTheme'
import { useAppSelector } from 'Store/hooks'
import { Container } from 'styled-bootstrap-grid'

import {
  GET_CLIENT_LOCATION_ID,
  GET_NEXT_STEPS_GUIDES,
  GET_NEXT_STEPS_LOCATION_DATA,
} from './gql'

export type BenefitGuide = Pick<Service, 'id' | 'name' | 'toolkitUrl'> & {
  categoryName: ServiceCategory['name']
  openEligibilityKey: ServiceCategory['openEligibilityKey']
}
const BenefitGuides = () => {
  const { id: clientId } = useAppSelector((state) => state.client)
  const navigate = useNavigate()
  const isTabletAndMobile = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })
  const isMobile = useMediaQuery({
    query: MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY,
  })
  const [customContent, setCustomContent] = useState('')

  const [benefitGuides, setBenefitGuides] = useState<
    Dictionary<BenefitGuide[]> | undefined
  >(undefined)

  const { data: customTextData, loading: customTextLoading } = useQuery(
    GET_NEXT_STEPS_LOCATION_DATA,
    {
      variables: {
        id: clientId,
      },
      skip: !clientId,
    }
  )

  const { data: clientLocationData } = useQuery(GET_CLIENT_LOCATION_ID, {
    variables: {
      id: clientId,
    },
  })

  const { data: benefitGuidesData, loading: benefitGuidesLoading } = useQuery(
    GET_NEXT_STEPS_GUIDES,
    {
      variables: {
        clientLocationId:
          clientLocationData?.client?.clientLocations[0]?.id ?? '',
      },
      skip: !clientLocationData,
    }
  )

  const customNextSteps =
    customTextData?.client?.screening?.location?.customNextSteps

  useEffect(() => {
    setCustomContent(customNextSteps)
  }, [customNextSteps])

  useEffect(() => {
    if (benefitGuidesData) {
      setBenefitGuides(mapAndGroupBenefitsGuides(benefitGuidesData))
    }
  }, [benefitGuidesData])

  return customTextLoading ? (
    <NextStepsLoading />
  ) : (
    <Container
      style={{
        paddingTop: !isMobile ? '2rem' : '0',
        overflowX: isMobile ? 'hidden' : 'visible',
      }}
    >
      <StyledWrapper>
        <StyledFlexWrapper justifyContent='space-between'>
          <StyledMainHeading
            className='underline'
            style={{
              color: 'hsla(162, 85%, 22%, 1)',
              fontWeight: '600',
              paddingBottom: isMobile ? '8px' : '1rem',
            }}
          >
            Next Steps
          </StyledMainHeading>
          {isMobile ? (
            <ButtonWrapperBackground>
              <StyledDynamicCtaButton
                component={Link}
                to={'/locations/list'}
                onClick={() => navigate('/locations/list')}
                // background={singleStopGreen}
                background={'hsla(162, 85%, 22%, 1)'}
                textshadow='none'
                border={'0.0625rem solid ' + singleStopGreen}
                margin='0'
                padding={isTabletAndMobile ? '2rem' : '1rem 2rem'}
                fontSize={isTabletAndMobile ? '0.8125rem' : '0.6875rem'}
                width={isMobile ? 'calc(100% - 2rem)' : 'auto'}
                height={isTabletAndMobile ? '4rem' : '3rem'}
                justcont={isTabletAndMobile ? 'flex-start' : ''}
                borderhover={'0.0625rem solid ' + singleStopGreen}
                $nextSteps={true}
                role='link'
                label={'Explore Resources'}
                style={{
                  borderRadius: '56px',
                }}
                position={'absolute'}
                top={'8px'}
                left={'50%'}
                transform={'translateX(-50%)'}
                justify={'center'}
                display={'flex'}
              >
                Explore Resources
              </StyledDynamicCtaButton>
            </ButtonWrapperBackground>
          ) : (
            <StyledDynamicCtaButton
              component={Link}
              to={'/locations/list'}
              onClick={() => navigate('/locations/list')}
              // background={singleStopGreen}
              background={'hsla(162, 85%, 22%, 1)'}
              textshadow='none'
              border={'0.0625rem solid ' + singleStopGreen}
              margin='0'
              padding={isTabletAndMobile ? '2rem' : '1rem 2rem'}
              fontSize={isTabletAndMobile ? '0.8125rem' : '0.6875rem'}
              width={'auto'}
              height={isTabletAndMobile ? '4rem' : '3rem'}
              justcont={isTabletAndMobile ? 'flex-start' : ''}
              borderhover={'0.0625rem solid ' + singleStopGreen}
              $nextSteps={true}
              role='link'
              label={'Explore Resources'}
              style={{
                borderRadius: '56px',
              }}
              position={'absolute'}
              bottom={'0'}
              left={'50%'}
              transform={'translateX(-50%)'}
            >
              Explore Resources
            </StyledDynamicCtaButton>
          )}
        </StyledFlexWrapper>
        {customContent ? (
          <StyledCustomContent
            dangerouslySetInnerHTML={{ __html: customContent }}
          />
        ) : (
          <NextStepsDefaultCopy />
        )}
        {/* benefit Guide will create another wrapper */}
        <Benefitwrapper>
          {/* <StyledWrapper> */}
          <StyledMainHeading>Benefit Guides</StyledMainHeading>
          <StyledParagraph color={theme.palette.color.black}>
            Downloadable benefit guides for each of your likely eligible
            services are linked below.
          </StyledParagraph>
          {/* </StyledWrapper> */}
          {benefitGuidesLoading && <NextStepsLoading />}
          {benefitGuides && <BenefitGuidesList benefitGuides={benefitGuides} />}
        </Benefitwrapper>
        {/* benefit Guide will create another wrapper */}
      </StyledWrapper>
      <style>{`
        @media (max-width: 480px) {
          .underline {
            position: relative;
          }
          .underline::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -30px;
            height: 1px;
            width: calc(100% + 60px);
            background: hsla(0, 0%, 94%, 1) !important;
          }
        }
      `}</style>
    </Container>
  )
}

export default BenefitGuides
