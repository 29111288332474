import React from 'react'
import { formatToDollarsEven, toCapitalized } from 'Shared/helpers'

import { StyledBenefitAmount, StyledBenefitsSpan } from './style'

interface RecurringBenefitAmountProps {
  frequency: string
  recurringAmount: number
}
const RecurringBenefitAmount = ({
  frequency,
  recurringAmount,
}: RecurringBenefitAmountProps) =>
  recurringAmount && frequency && frequency !== 'NONRECURRENT' ? (
    <div>
      <StyledBenefitsSpan>{`${toCapitalized(frequency)}: `}</StyledBenefitsSpan>
      <StyledBenefitAmount highlight={true} small>
        {formatToDollarsEven(recurringAmount)}
      </StyledBenefitAmount>
    </div>
  ) : null

export default RecurringBenefitAmount
