import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY } from 'Shared/constants'

import { StyledWrapper } from './style'

const NextStepsDefaultCopy = () => {
  const isMobile = useMediaQuery({
    query: MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY,
  })
  return (
    <StyledWrapper style={{ paddingBottom: isMobile ? '16px' : '2rem' }}>
      <p style={{ color: 'hsla(240, 10%, 25%, 1)', fontWeight: '400' }}>
        Now that you've completed the screening process, it's time to get ready
        to apply for benefits.
      </p>
      <p style={{ color: 'hsla(240, 10%, 25%, 1)', fontWeight: '400' }}>
        Benefit guides for your eligible programs can be found below. These
        guides have important information about the documents you'll need as
        well as how to navigate the application process.
      </p>
    </StyledWrapper>
  )
}

export default NextStepsDefaultCopy
