import styled from 'styled-components'

export const DescriptionWrapper = styled.article`
  // padding-top: 1rem;
`
export const DescriptionContent = styled.div`
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 450;
  font-family: 'Noto Sans HK', sans-serif;
`
