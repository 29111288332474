import styled, { CSSProperties } from 'styled-components'

export const StyledCardWrapper = styled.div`
  max-width: 710px;
  border-radius: 24px;
  padding: 48px 70px;
  margin: 0 auto;
  height: 80vh;
`
export const StyledBenefitCardWrapper = styled.div`
  max-width: 710px;
  border-radius: 24px;
  padding: 40px;
  margin: 0 auto;
  height: auto;
  border: 1px rgba(216, 216, 216, 1) solid;
  @media (max-width: 480px) {
    border: none;
    padding: 16px;
  }
`
export const BtnBenefitWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 16px;
  @media (max-width: 480px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    padding: 1rem;
    width: 100%;
  }
`
export const Benefitcontainer = styled.div`
  border-radius: 12px;
  background: hsla(0, 0%, 100%, 1);
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 12px;
`
export const Benefitsubcategories = styled.div<{ isChecked: boolean }>`
  border-radius: 8px;
  border: 1px solid var(--stroke-stroke-gray, #efefef);
  padding: 12px;
  margin-bottom: 8px;
  height: auto;
  background-color: ${(props) =>
    props.isChecked ? 'hsla(164, 48%, 95%, 1)' : 'transparent'};
`
export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
`
export const StyledHeaderBenefit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 24px;
  gap: 16px;
`
export const StyledBenefitScrollerBox = styled.div`
  border-radius: 10px !important;
  padding: 16px;
  height: 60vh;
  /* overflow: scroll; */
  scrollbar-width: thin;
  margin-top: 1.5rem;
  overflow-y: scroll;
  background-color: hsla(164, 48%, 95%, 1);
  border-radius: 10px !important;
  &::-webkit-scrollbar {
    width: 8px !important;
    -webkit-border-radius: 10px !important;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px !important;
    background-color: red !important;
    -webkit-border-radius: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background-color: red !important;
    -webkit-border-radius: 10px !important;
  }
`

export const StyledHeaderContainer = styled.div`
  margin: 0 0 0 1rem;
`

export const Heading = styled.h1`
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0.5rem 0 0 0;
  color: ${(props) => props.color || ''};
`

export const Text = styled.p<CSSProperties>`
  font-size: 1rem;
  margin: 0.55rem 0 0 0;
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
`

export const StyledScrollerBox = styled.div`
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 2rem 1.4rem;
  height: 60vh;
  overflow: scroll;
  margin-top: 1.5rem;
`

export const StyledScrollerBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const StyledHorizontalRule = styled.hr`
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 0 0 1rem 0;
`
