import React, { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import {
  ClientDataDocument,
  GetClientSummaryDocument,
  SavePreexistingBenefitsDocument,
} from '__generated__/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { SvgIcon } from '@material-ui/core'
import { StyledFormControlLabel } from 'Components/CaseManagement/ClientRecords/FilterColumn/style'
import CaseCategoryHeading from 'Components/CaseManagement/ClientSummary/Cases/CaseCategoryHeading'
import { parseServices } from 'Components/CaseManagement/helpers'
import { BenefitStyledCheckbox } from 'Components/CaseManagement/Modals/style'
import HeaderWithContainerBenefitCheckList from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerBenefitCheckList'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'
import PageNotFound from 'Pages/PageNotFound/PageNotFound'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { isFeatureToggleOn } from 'Shared/helpers'
import { RootState } from 'Store'
import { Container } from 'styled-bootstrap-grid'

import { FAQIcon } from './IconPaths'
import {
  Benefitcontainer,
  Benefitsubcategories,
  BtnBenefitWrapper,
  Heading,
  StyledBenefitCardWrapper,
  StyledBenefitScrollerBox,
  StyledHeaderBenefit,
  StyledHeaderContainer,
  StyledScrollerBoxContent,
  Text,
} from './style'

const BenefitsRecieved = () => {
  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })
  const navigate = useNavigate()
  const { data } = useQuery(ClientDataDocument)
  const clientLocationId = useSelector(
    (state: RootState) => state.client.clientLocationId
  )
  const { data: assistedScreeningClientData, loading } = useQuery(
    GetClientSummaryDocument,
    {
      variables: {
        id: clientLocationId || sessionStorage.getItem('clientLocationId'),
      },
    }
  )
  const [savePreexistingBenefits] = useMutation(SavePreexistingBenefitsDocument)
  const clientData =
    data?.me?.userable?.__typename === 'Client'
      ? data?.me?.userable
      : assistedScreeningClientData?.clientLocation?.client

  const [selected, setSelected] = React.useState('')
  const [serviceIds, setServiceIds] = React.useState<string[]>([])
  if (loading) {
    return <LoadingIndicator show={true} />
  }

  if (!isFeatureToggleOn('already_receiving_benefits')) {
    if (clientData) {
      navigate('/screening')
    }
    return <PageNotFound />
  }

  if (!data || !clientData) {
    return null
  }

  const screeningId = clientData.screening?.id

  const parsedScreenableServices = parseServices({
    services: clientData.screenableServices,
  })

  const handleSubmit = () => {
    if (screeningId && serviceIds) {
      savePreexistingBenefits({
        variables: {
          screeningId,
          serviceIds,
        },
      })
        .then(() => {
          navigate('/eligibility')
        })
        .catch((error) => {
          console.log('savePreexistingBenefits error: ', error)
        })
    }
  }

  const handleCheckBoxChange = (
    event: ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: any
  ) => {
    if (event.target.checked) {
      setServiceIds([...serviceIds, option?.id])
    } else {
      setServiceIds(serviceIds.filter((b) => b !== option?.id))
    }
  }

  return (
    <HeaderWithContainerBenefitCheckList padding={0}>
      <Container>
        <StyledBenefitCardWrapper>
          <StyledHeaderBenefit>
            <SvgIcon
              style={{
                width: '56px',
                height: '56px',
              }}
            >
              {FAQIcon}
            </SvgIcon>
            <StyledHeaderContainer>
              <Heading color='rgba(8, 103, 74, 1)'>Current Benefits</Heading>
              <Text marginBottom={selected ? 'auto' : '50px'}>
                Are you currently receiving funds for or participating in any
                state or federal benefit programs?
              </Text>
            </StyledHeaderContainer>
          </StyledHeaderBenefit>
          {!selected && (
            <>
              <Button
                secondary
                margin='0 0 0 0'
                onClick={() => setSelected('yes')}
              >
                Yes
              </Button>
              <Button
                secondary
                margin='0 0 0 1rem'
                onClick={() => navigate('/eligibility')}
              >
                No
              </Button>
              <Button
                secondary
                margin='0 0 0 1rem'
                onClick={() => setSelected('do_not_know')}
              >
                I don't know
              </Button>
            </>
          )}
          {(selected === 'yes' || selected === 'do_not_know') && (
            <>
              <Text>Please check all that apply</Text>
              <StyledBenefitScrollerBox>
                <StyledScrollerBoxContent>
                  {Object.keys(parsedScreenableServices).map((key) => {
                    return (
                      <Benefitcontainer>
                        <CaseCategoryHeading
                          key={key}
                          openEligibilityKey={key}
                          $fontSize='20px'
                          $iconMargin='0 20px 0 0'
                        />
                        {parsedScreenableServices[key].map((option, i) => {
                          const isChecked = serviceIds.includes(option.id)
                          return (
                            <Benefitsubcategories
                              key={option.id}
                              isChecked={isChecked}
                            >
                              <StyledFormControlLabel
                                style={{ marginBottom: '0' }}
                                control={
                                  <BenefitStyledCheckbox
                                    data-testid={`checkbox_${option.id}`}
                                    checked={isChecked}
                                    onChange={handleCheckBoxChange}
                                    value={option.id}
                                  />
                                }
                                label={option.name}
                              />
                            </Benefitsubcategories>
                          )
                        })}
                        {/* <StyledHorizontalRule /> */}
                      </Benefitcontainer>
                    )
                  })}
                </StyledScrollerBoxContent>
              </StyledBenefitScrollerBox>

              <BtnBenefitWrapper>
                {serviceIds.length > 0 && (
                  <Button
                    primary
                    margin='1rem 0 0 0'
                    onClick={() => setServiceIds([])}
                    radius='56px'
                    padding='10px 16px 10px 16px'
                    width='164px'
                    style={{
                      background: 'rgba(255, 255, 255, 1)',
                      border: '1px solid #EFEFEF',
                      color: 'rgba(21, 21, 21, 1)',
                      textShadow: 'none',
                      marginTop: isMobile && '2rem',
                    }}
                  >
                    Clear All
                  </Button>
                )}
                <Button
                  primary
                  margin='1rem 0 0 0'
                  onClick={() => handleSubmit()}
                  radius='56px'
                  padding='10px 16px 10px 16px'
                  width='164px'
                  disabled={serviceIds.length === 0}
                  style={{
                    backgroundColor:
                      serviceIds.length === 0 ? 'hsla(0, 0%, 78%, 1)' : '',
                    width: isMobile && !serviceIds.length && '100%',
                    marginTop: isMobile && '2rem',
                  }}
                >
                  Submit
                </Button>
              </BtnBenefitWrapper>
            </>
          )}
        </StyledBenefitCardWrapper>
      </Container>
    </HeaderWithContainerBenefitCheckList>
  )
}

export default BenefitsRecieved
