import React from 'react'
import { StyledParagraph } from 'Components/Screener/style'
import CalendarDays from 'Components/Shared/CalendarDays/CalendarDays'
import CalendarMonths from 'Components/Shared/CalendarMonths/CalendarMonths'
import CalendarYears from 'Components/Shared/CalendarYears/CalendarYears'
import { Col, Row } from 'styled-bootstrap-grid'

import FieldSelect from './FieldSelect'

const DateTypeField = ({ name, validate, label, children }) => {
  const normalize = (value) => +value

  return (
    <Col md='3.5'>
      <FieldSelect
        name={name}
        label={label}
        validate={validate}
        normalize={normalize}
      >
        {children}
      </FieldSelect>
    </Col>
  )
}

const MilitaryDateSubSelector = ({
  meta: { error },
  input: { name },
  namePrefix,
  text,
}) => {
  const validateMonth = React.useCallback(
    (value) => (!value ? 'Enter a month' : error?.[`${namePrefix}Month`]),
    [error, namePrefix]
  )
  const validateDay = React.useCallback(
    (value) => (!value ? 'Enter a day' : error?.[`${namePrefix}Day`]),
    [error, namePrefix]
  )
  const validateYear = React.useCallback(
    (value) => (!value ? 'Enter a year' : error?.[`${namePrefix}Year`]),
    [error, namePrefix]
  )
  return (
    <Row>
      <Col md='1.5' alignSelf='center'>
        <StyledParagraph marginBottom='0'>{text}</StyledParagraph>
      </Col>
      <DateTypeField
        name={`${name}.${namePrefix}_month`}
        validate={validateMonth}
        label='Month'
      >
        {CalendarMonths()}
      </DateTypeField>
      <DateTypeField
        name={`${name}.${namePrefix}_day`}
        validate={validateDay}
        label='Day'
      >
        {CalendarDays()}
      </DateTypeField>
      <DateTypeField
        name={`${name}.${namePrefix}_year`}
        validate={validateYear}
        label='Year'
      >
        {CalendarYears()}
      </DateTypeField>
    </Row>
  )
}

export default MilitaryDateSubSelector
