import * as React from 'react'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import MenuItem from '@mui/material/MenuItem'
import {
  clearFilter,
  updateCategoriesFilter,
  updateFilteredServiceTags,
  updateFilterLevel,
} from 'Actions/filters'
import Icon from 'Components/Shared/Icon/Icon'
import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import {
  categoriesArrowIconPath,
  categoriesBlackIconPath,
  categoriesWhiteIconPath,
} from 'Shared/Icons/CategoryIcons/CategoriesFilterIconsPath'
import {
  arrowDownBlackIconPath,
  arrowUpWhiteIconPath,
} from 'Shared/Icons/LocationInfo/paths'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import CategoryFilterItemMegaMenu from '../CategoryFilterItem/CategoryFilterItemMegaMenu'
import { StyledFilterBtn, StyledFilterFlex } from '../FilterMenu/style'
import useCommunityResourcesCategories, {
  Category,
} from '../useCommunityResourcesCategories'

import {
  StyledCategoryFilterButton,
  StyledCategoryFilterMenu,
  StyledCategoryFilterMobileMenu,
  StyledCategoryFilterWrapper,
  StyledMenuLastLevel,
  StyledMenuLevelLabel,
  StyledMenuLevelSpan,
  StyledMenuSecondLevel,
  StyledMenuTopLevel,
} from './Style'

const CategoryFilterMegaMenu = ({
  showResults,
}: {
  showResults?: () => void
}) => {
  const dispatch = useAppDispatch()

  const { resourcesZipCode } = useAppSelector((state) => state.geolocation)
  const { categoriesFilter, serviceTags } = useAppSelector(
    (state) => state.filters
  )
  const {
    currentFilterLevel,
    topLevelLabel,
    secondLevelLabel,
    lastLevelLabel,
  } = categoriesFilter
  const { categories } = useCommunityResourcesCategories(resourcesZipCode)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [secondLevelList, setSecondLevelList] = useState<Category[]>([])
  const [lastLevelList, setLastLevelList] = useState<Category[]>([])

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })
  const isDesktop = useMediaQuery({ query: DESKTOP_MEDIA_QUERY })
  const isMobileOrTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })

  useEffect(() => {
    if (categoriesFilter && categories?.length) {
      if (secondLevelLabel) {
        updateSecondLastLevel()
      } else if (topLevelLabel) {
        updateSecondLevel()
      } else {
        setSecondLevelList([])
        setLastLevelList([])
      }
    }
  }, [categoriesFilter, categories])

  const updateSecondLevel = (): Category[] | null => {
    const selectedCategory = categories.find(
      (category) => category.label === topLevelLabel
    )
    if (selectedCategory) {
      setSecondLevelList(selectedCategory.children)
      return selectedCategory.children
    } else {
      handleFilterUpdate('', 'secondLevelLabel', 'first', true)
      return null
    }
  }

  const updateSecondLastLevel = () => {
    const secondLevelValue = updateSecondLevel()
    if (secondLevelValue) {
      const selectedSecondLevel = secondLevelValue.find(
        (item) => item.label === secondLevelLabel
      )
      if (selectedSecondLevel) {
        setLastLevelList(selectedSecondLevel.children || [])
      } else {
        handleFilterUpdate('', 'lastLevelLabel', 'second', true)
      }
    }
  }
  const handleFilterUpdate = (
    label: string,
    key: string,
    level: string,
    hasChildList: boolean
  ) => {
    const filter = { ...categoriesFilter }
    if (level === 'second') {
      filter.secondLevelLabel = ''
      filter.lastLevelLabel = ''
    }
    filter[key] = label
    filter.currentFilterLevel = level
    dispatch(updateCategoriesFilter(filter))
    if (!hasChildList) updateFilterTags(label)
  }

  const updateFilterTags = (label: string) => {
    dispatch(updateFilteredServiceTags(label))
    handleClose()
  }

  const handleClearAll = () => {
    dispatch(clearFilter())
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (showResults) {
      showResults()
      dispatch(updateFilterLevel('first'))
    }
  }
  return (
    <>
      {isDesktop && (
        <StyledCategoryFilterWrapper>
          <StyledCategoryFilterButton
            id='category-filter-button'
            aria-controls={open ? 'category-filter-button' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            variant='contained'
            disableElevation
            onClick={handleClick}
            isOpen={open}
          >
            <Icon
              fill={'none'}
              scale={'1.25rem'}
              viewBox={'0 0 24 24'}
              margin={'0 .5rem 0 0'}
              screenReaderLabel={'Categories Filter'}
            >
              {open ? categoriesWhiteIconPath : categoriesBlackIconPath}
            </Icon>
            Categories
            <Icon
              fill={'none'}
              scale={'1rem'}
              viewBox={'0 0 14 8'}
              margin={'0 0 0 auto'}
              screenReaderLabel={'Categories Filter Menu'}
            >
              {open ? arrowUpWhiteIconPath : arrowDownBlackIconPath}
            </Icon>
          </StyledCategoryFilterButton>
          <StyledCategoryFilterMenu
            id='category-filter-menu'
            MenuListProps={{
              'aria-labelledby': 'category-filter-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <StyledMenuTopLevel>
              {categories.map((node, i) => {
                return (
                  <MenuItem
                    key={i}
                    id={node.id}
                    className={`item ${node?.label === topLevelLabel ? 'selected' : ''}`}
                    onClick={() => {
                      handleFilterUpdate(
                        node.label,
                        'topLevelLabel',
                        'second',
                        node?.children?.length > 0
                      )
                    }}
                    disableRipple
                  >
                    <CategoryFilterItemMegaMenu
                      category={node}
                      id={i}
                      key={node.label}
                    />
                  </MenuItem>
                )
              })}
            </StyledMenuTopLevel>

            <StyledMenuSecondLevel $isOpen={secondLevelList.length > 0}>
              {secondLevelList.map((child, i) => {
                return (
                  <MenuItem
                    key={i}
                    id={child.label}
                    className={`item ${child.label === secondLevelLabel ? 'selected' : ''}`}
                    onClick={() => {
                      handleFilterUpdate(
                        child.label,
                        'secondLevelLabel',
                        child?.children ? 'all' : 'second',
                        child?.children !== undefined
                      )
                    }}
                    disableRipple
                  >
                    <StyledMenuLevelLabel>{child.label}</StyledMenuLevelLabel>
                    {child?.children ? (
                      <StyledMenuLevelSpan>
                        <Icon
                          fill='none'
                          stroke='#151515'
                          scale={'.875rem'}
                          viewBox={'0 0 8 14'}
                          margin={'0 0 0 auto'}
                          screenReaderLabel={'go to category'}
                        >
                          {categoriesArrowIconPath}
                        </Icon>
                      </StyledMenuLevelSpan>
                    ) : null}
                  </MenuItem>
                )
              })}
            </StyledMenuSecondLevel>
            {lastLevelList?.length > 0 && (
              <StyledMenuLastLevel $isOpen={lastLevelList.length > 0}>
                {lastLevelList?.map((child, i) => {
                  return (
                    <>
                      <MenuItem
                        key={i}
                        id={child.label}
                        className={`item ${child.label === lastLevelLabel ? 'selected' : ''}`}
                        onClick={() => {
                          handleFilterUpdate(
                            child.label,
                            'lastLevelLabel',
                            'all',
                            false
                          )
                        }}
                        disableRipple
                      >
                        <StyledMenuLevelLabel>
                          {child.label}
                        </StyledMenuLevelLabel>
                        {child.count && (
                          <StyledMenuLevelSpan>{` (${child.count})`}</StyledMenuLevelSpan>
                        )}
                      </MenuItem>
                    </>
                  )
                })}
              </StyledMenuLastLevel>
            )}
          </StyledCategoryFilterMenu>
          {serviceTags && (
            <StyledFilterBtn
              $categoriesFilterActionsDesktop={true}
              onClick={handleClearAll}
            >
              Clear All
            </StyledFilterBtn>
          )}
        </StyledCategoryFilterWrapper>
      )}
      {isMobileOrTablet && (
        <StyledCategoryFilterMobileMenu $level={currentFilterLevel}>
          <StyledMenuTopLevel $isMobileOrTablet={true}>
            <div>
              {categories.map((node, i) => {
                return (
                  <MenuItem
                    key={i}
                    id={node.id}
                    className={`item ${node?.label === topLevelLabel ? 'selected' : ''}`}
                    onClick={() => {
                      handleFilterUpdate(
                        node.label,
                        'topLevelLabel',
                        'second',
                        node?.children?.length > 0
                      )
                    }}
                    disableRipple
                  >
                    <CategoryFilterItemMegaMenu
                      category={node}
                      id={i}
                      key={node.label}
                    />
                  </MenuItem>
                )
              })}
            </div>
            {isMobile && (
              <StyledFilterFlex $categoriesFilterActions={true}>
                <StyledFilterBtn
                  $categoriesFilterActions={true}
                  onClick={handleClearAll}
                >
                  Reset Filters
                </StyledFilterBtn>
                <StyledFilterBtn
                  $categoriesFilterActions={true}
                  $isResult={true}
                  onClick={showResults}
                >
                  Show Results
                </StyledFilterBtn>
              </StyledFilterFlex>
            )}
          </StyledMenuTopLevel>

          <StyledMenuSecondLevel
            $isMobileOrTablet={true}
            $isOpen={secondLevelList.length > 0}
          >
            {secondLevelList.map((child, i) => {
              return (
                <MenuItem
                  key={i}
                  id={child.label}
                  className={`item ${child.label === secondLevelLabel ? 'selected' : ''}`}
                  onClick={() => {
                    handleFilterUpdate(
                      child.label,
                      'secondLevelLabel',
                      child?.children ? 'all' : 'second',
                      child?.children !== undefined
                    )
                  }}
                  disableRipple
                >
                  <StyledMenuLevelLabel>{child.label}</StyledMenuLevelLabel>
                  {child?.children ? (
                    <StyledMenuLevelSpan>
                      <Icon
                        fill='none'
                        stroke='#151515'
                        scale={'.875rem'}
                        viewBox={'0 0 8 14'}
                        margin={'0 0 0 auto'}
                        screenReaderLabel={'go to category'}
                      >
                        {categoriesArrowIconPath}
                      </Icon>
                    </StyledMenuLevelSpan>
                  ) : null}
                </MenuItem>
              )
            })}
          </StyledMenuSecondLevel>

          <StyledMenuLastLevel
            $isMobileOrTablet={true}
            $isOpen={lastLevelList.length > 0 || secondLevelList.length > 0}
          >
            {lastLevelList?.map((child, i) => {
              return (
                <>
                  <MenuItem
                    key={i}
                    className={`item ${child.label === lastLevelLabel ? 'selected' : ''}`}
                    id={child.label}
                    onClick={() => {
                      handleFilterUpdate(
                        child.label,
                        'lastLevelLabel',
                        'all',
                        false
                      )
                    }}
                    disableRipple
                  >
                    <StyledMenuLevelLabel>{child.label}</StyledMenuLevelLabel>
                    {child.count && (
                      <StyledMenuLevelSpan>{` (${child.count})`}</StyledMenuLevelSpan>
                    )}
                  </MenuItem>
                </>
              )
            })}
          </StyledMenuLastLevel>
        </StyledCategoryFilterMobileMenu>
      )}
    </>
  )
}

export default CategoryFilterMegaMenu
