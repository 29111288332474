import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

type StyledActionForMobAndTabProps = {
  color?: string
}

const StyledActionForMobAndTab = css<StyledActionForMobAndTabProps>`
  min-width: auto;
  width: 4rem;
  height: 4rem;
  padding: 0;
  color: ${(props) => (props.color ? props.color : theme.palette.color.white)};
`

type StyledActionProps = {
  padding?: string
  flex?: string
  $opacity?: number
  $background?: string
  $justifyContent?: string
  $activeFilter?: boolean
  $filterInMobile?: boolean
  $toggleLocationMobileList?: boolean
}

export const StyledAction = styled.button<StyledActionProps>`
  align-items: center;
  color: ${theme.palette.color.black};
  display: flex;
  font-weight: 600;
  opacity: ${(props) => props.$opacity};
  font-size: 1.2rem;
  border: inherit;
  text-transform: capitalize;
  background-color: ${(props) =>
    props.$background ?? theme.palette.color.lightGrey};
  border-radius: 50px;
  justify-content: ${(props) => props.$justifyContent ?? 'center'};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${theme.palette.secondary.default};
  }

  ${media.smaller`
    ${StyledActionForMobAndTab}
  `};

  ${media.tablet`
    ${StyledActionForMobAndTab}
  `};

  ${media.desktop`
    color: ${theme.palette.color.black};
  width: 3rem;
  height: 3rem;
    &:hover {
      cursor: pointer;
      background-color: ${theme.palette.secondary.default};
    };
  `}

  ${({ $activeFilter }) =>
    $activeFilter &&
    css`
      ${media.smaller`
      width: 3.7rem;
      height: 3.7rem;
      background-color: unset;
      &:hover {
      background-color: ${theme.palette.secondary.default};
      };
  `};
      ${media.tablet`
      width: 3.39rem;
      height: 3.39rem;
      background-color: ${theme.palette.color.lightGrey};
      &:hover {
      background-color: ${theme.palette.secondary.default};
      };
  `};
      ${media.desktop`
      background: #000;
  width: 3rem;
  height: 3rem;
      &:hover {
      background: #000;
      };
  `};
    `};

  ${({ $toggleLocationMobileList }) =>
    $toggleLocationMobileList &&
    css`
      ${media.smaller`
        min-width: auto;
        height: 4rem;
        padding: 0;
        color: #fff;
        width: 100%;
        align-items: center;
        justify-content: center;
        &:hover{
          background-color: unset;
        }
    `}
    `}
`

StyledAction.displayName = 'StyledAction'
