import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`

export const StyedNotificationBanner = styled.div`
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: 0;
  color: #721c24;
  padding: 0.75rem 1.25rem;
  position: absolute;
  width: 100%;
  z-index: 100;
  height: 50px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;
`

export const MapWrapper = styled.div`
  @media (min-width: 992px) and (max-width: 1024px) {
    height: calc(-85px + 100vh) !important;
  }
  @media (min-width: 800px) and (max-width: 992px) {
    height: calc(-70px + 100vh) !important;
  }
  @media (min-width: 574px) and (max-width: 767px) {
    height: calc(-123px + 100vh) !important;
    #locations-map {
      height: 100% !important;
    }
  }
  @media (min-width: 481px) and (max-width: 573px) {
    height: calc(-90px + 100vh) !important;
  }
`
StyledContainer.displayName = 'StyledContainer'
