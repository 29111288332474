import React, { ChangeEvent, useState } from 'react'
import { Form } from 'react-final-form'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import CheckboxInput from 'Components/Shared/CheckboxInput/CheckboxInput'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import { FORM_ERROR, MutableState } from 'final-form'
import { clientLocationId } from 'Shared/helpers'

import { StyledButtonReferral } from '../style.ts'

import CheckField from './CheckFieldForm'
import {
  CancelButton,
  FooterForm,
  StyledDialogContent,
  StyledFormControl,
  StyledParagraph,
  StyledServiceContainer,
} from './style'

interface StartReferral {
  open: boolean
  location: {
    id: string
    name: string
    address: Record<string, string>
    numbers: Record<string, string>
    algoliaQuickFilters: number[]
  }
  serviceBadges: React.ReactElement[]
  handleClose: () => void
  submitReferral: (values: {
    locationId: string
    services: number[]
    name: string
    address: Record<string, string>
    numbers: Record<string, string>
  }) => void
  userData: {
    isACaseManager: boolean
  }
  parentCategoryIdFromContext?: number
}

interface FormValues {
  services?: number[]
}

interface MutatorState extends Omit<MutableState<FormValues>, 'formState'> {
  formState: {
    values: {
      services: number[]
    }
  }
}

const StartReferralModal: React.FC<StartReferral> = ({
  open,
  location,
  serviceBadges,
  handleClose,
  submitReferral,
  userData,
  parentCategoryIdFromContext,
}) => {
  const [checkAll, setCheckAll] = useState<boolean>(false)
  const isCaseManagerBrowsingAsClient =
    userData?.isACaseManager &&
    clientLocationId() &&
    window.location.pathname.includes('/locations/')

  const serviceIds = Array.from(new Set(location.algoliaQuickFilters)) || []

  const validValues = (values: FormValues, serviceIds: number[]) =>
    serviceIds.length !== 0 &&
    (!values.services || values.services.length === 0)

  const getCheckAllValue = (
    services: number[],
    serviceIds: number[],
    event: ChangeEvent<HTMLInputElement>
  ) => services.length === serviceIds.length - 1 && event.target.checked

  const getServiceValues = (status: boolean, serviceIds: number[]) =>
    status ? serviceIds : []

  const onSubmit = async (values: FormValues) => {
    let services: [] | number[] = values.services || []
    if (userData?.isACaseManager && !isCaseManagerBrowsingAsClient) {
      services = parentCategoryIdFromContext
        ? [Number(parentCategoryIdFromContext)]
        : []
    }
    if (validValues(values, serviceIds) && !userData?.isACaseManager) {
      return { [FORM_ERROR]: 'Select at least one service' }
    }
    setCheckAll(false)
    submitReferral({
      locationId: location.id,
      services,
      name: location.name,
      address: location.address,
      numbers: location.numbers,
    })
  }

  const checkAllAction = (
    event: boolean[],
    state: MutableState<FormValues>
  ) => {
    setCheckAll(event[1])
    state.fields['services'].change(getServiceValues(event[1], serviceIds))
  }

  const checkGroupServices = (
    event: ChangeEvent<HTMLInputElement>[],
    state: MutatorState
  ) => {
    const selectedServices = state.formState?.values?.services || []
    setCheckAll(getCheckAllValue(selectedServices, serviceIds, event[0]))
  }

  return (
    <ModalTemplate
      open={open}
      hasXToClose={false}
      hasCalendar={false}
      handleCancel={handleClose}
      heading={`Starting Referral for: ${location.name}`}
      width='40%'
      modalPadding={'2rem'}
    >
      <StyledDialogContent>
        {(!userData?.isACaseManager || isCaseManagerBrowsingAsClient) &&
          //ToDo: Find Help API page crashes without optional chaining below
          serviceBadges?.length !== 0 && (
            <div>
              <h4>Select service(s):</h4>
              <p>
                Please select the services you're interested in at this
                location:
              </p>
            </div>
          )}
        <FormWrapper
          onSubmit={onSubmit}
          checkGroupServices={checkGroupServices}
          checkAllAction={checkAllAction}
          serviceBadges={serviceBadges}
          userData={userData}
          checkAll={checkAll}
          setCheckAll={setCheckAll}
          handleClose={handleClose}
          isCaseManagerUpdatingClientRecord={isCaseManagerBrowsingAsClient}
        />
      </StyledDialogContent>
    </ModalTemplate>
  )
}

interface FormWrapperProps {
  onSubmit: (values: FormValues) => void
  checkGroupServices: (
    event: ChangeEvent<HTMLInputElement>[],
    state: unknown
  ) => void
  checkAllAction: (event: boolean[], state: MutableState<FormValues>) => void
  serviceBadges: React.ReactElement[]
  userData: {
    isACaseManager: boolean
  }
  checkAll: boolean
  setCheckAll: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
  isCaseManagerUpdatingClientRecord: boolean
}

const FormWrapper: React.FC<FormWrapperProps> = ({
  onSubmit,
  checkGroupServices,
  checkAllAction,
  serviceBadges,
  userData,
  checkAll,
  setCheckAll,
  handleClose,
  isCaseManagerUpdatingClientRecord,
}) => (
  <Form
    onSubmit={onSubmit}
    mutators={{ checkGroupServices, checkAllAction }}
    render={({
      handleSubmit,
      form,
      submitError,
      hasSubmitErrors,
      dirtySinceLastSubmit,
    }) => (
      <form onSubmit={handleSubmit}>
        <StyledServiceContainer>
          {(!userData?.isACaseManager || isCaseManagerUpdatingClientRecord) &&
            serviceBadges.map((child, i) => (
              <CheckField key={i} form={form}>
                {child}
              </CheckField>
            ))}
          {(!userData?.isACaseManager || isCaseManagerUpdatingClientRecord) &&
            serviceBadges.length > 1 && (
              <StyledFormControl>
                <CheckboxInput
                  input={{
                    checked: checkAll,
                    onChange: form.mutators.checkAllAction,
                    name: 'select-all',
                  }}
                  label='Select all'
                />
              </StyledFormControl>
            )}
        </StyledServiceContainer>
        {userData?.isACaseManager ? (
          <>
            <StyledParagraph>
              When a referral is started, an activity will be logged in this
              client's record. The client will also receive a message that
              includes the location's name, address, and contact information.
            </StyledParagraph>
            <StyledParagraph>
              Client details will not be sent to the referral location.
            </StyledParagraph>
          </>
        ) : (
          <>
            <StyledParagraph>
              By starting a referral, an entry will be made in your client
              record. Your case manager may follow up with you about your
              experience.
            </StyledParagraph>
            <StyledParagraph>
              We'll also send you a message that includes the location's name,
              address, and contact information.
            </StyledParagraph>
            <StyledParagraph>
              Details about this referral will not be sent to the referral
              location.
            </StyledParagraph>
          </>
        )}
        {hasSubmitErrors && !dirtySinceLastSubmit && (
          <FooterForm>
            <FormSubmissionError error={submitError} />
          </FooterForm>
        )}
        <FooterForm>
          <CancelButton
            onClick={() => {
              setCheckAll(false)
              handleClose()
            }}
          >
            Cancel
          </CancelButton>
          <StyledButtonReferral type='submit'>
            Start referral
          </StyledButtonReferral>
        </FooterForm>
      </form>
    )}
  />
)

export default StartReferralModal
