/* eslint-disable @typescript-eslint/await-thenable */
import React from 'react'
import { useDispatch } from 'react-redux'
import ConfirmOrCancel from 'Components/CaseManagement/Modals/Shared/ConfirmOrCancel'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import { change, submit } from 'redux-form'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'

import { StyledHeadingDialog, StyledParagraph } from './style'

const ConfirmSkipVerificationModal = ({ resetContext, open }) => {
  const dispatch = useDispatch()

  return (
    <ModalTemplate open={open} textAlign={'center'}>
      <StyledHeadingDialog>
        Are you sure you want to skip verification?
      </StyledHeadingDialog>
      <StyledParagraph textAlign='center' marginBottom='.5em'>
        Please note that by selecting “Yes,” the client will become unverified.
        The client will not be able to access their profile without the
        assistance of a case manager.
      </StyledParagraph>
      <ConfirmOrCancel
        handleOnCancel={resetContext}
        handleOnConfirm={async () => {
          await dispatch(
            change(PRE_SCREENER_FORM_NAME, 'verification_skipped', true)
          )
          dispatch(submit(PRE_SCREENER_FORM_NAME))
          resetContext()
        }}
        confirmLabel='Yes, Skip Verification'
        cancelLabel='Cancel'
      />
    </ModalTemplate>
  )
}

export default ConfirmSkipVerificationModal
