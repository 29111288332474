export interface MyBenefitsState {
  openBenefitModal: boolean
  openEditCaseStatusModal: boolean
  toolkitUrl?: string
  benefitDescription: string
  benefitName: string
  openEligibilityKey: string
  caseId: string
  updatedCaseStatus: string
  rehydrated: boolean
  persisted: boolean
}

export const myBenefits: MyBenefitsState = {
  openBenefitModal: false,
  openEditCaseStatusModal: false,
  toolkitUrl: '',
  benefitDescription: '',
  benefitName: '',
  openEligibilityKey: '',
  caseId: '',
  updatedCaseStatus: '',
  rehydrated: false,
  persisted: false,
}
