import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Box from '@material-ui/core/Box'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { parseQuery } from 'Actions/filters'
import { sortByKey } from 'Components/NextSteps/helpers'
import {
  StyledAppBar,
  StyledDynamicCtaButton,
  StyledMainHeading,
  StyledTabPanelDiv,
} from 'Components/Screener/style'
import useIsFeatureToggleOn from 'Components/Shared/Hooks/useIsFeatureToggleOn'
import Icon from 'Components/Shared/Icon/Icon'
import { StyledMuiTextFieldWrapper } from 'Components/Shared/ReduxForm/style'
import EligibilityBenefitListItem from 'Pages/Results/EligibilityBenefitListItem'
import EligibilityBenefitsTabs from 'Pages/Results/EligibilityBenefitsTabs'
import { categoriesSelector } from 'Selectors/benefits'
import { MOBILE_AND_TABLET_MEDIA_QUERY } from 'Shared/constants'
import backIconPath from 'Shared/Icons/backIconPath'
import { singleStopGreen } from 'Shared/Theme'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { useDebouncedCallback } from 'use-debounce'

import NextStepsFriendlyText from '../NextSteps/NextStepsFriendlyText'

import { CALCULATE_SCREENING_RESULTS } from './gql'
import { calculateTotalBenefitAmount } from './helpers'
import { StyledHeading, StyledList, StyledListItem } from './style'

import 'Components/Uielements/styles/globalStyle.css'

export const renderCategoryIcon = (categoryIconPath) => {
  return (
    <Icon fill={'unset'} scale={'4.5rem'} margin='0 1rem 0 0'>
      {categoryIconPath}
    </Icon>
  )
}
const Eligibility = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [getClientBenefitsCategories, { data, loading }] = useMutation(
    CALCULATE_SCREENING_RESULTS
  )
  const debouncedNavigate = useDebouncedCallback(navigate, 500)
  const { id: clientId, zipCode } = useSelector((state) => state.client)
  const isFeatureToggleOn = useIsFeatureToggleOn()
  const isNextSteps = isFeatureToggleOn('next_steps')

  useEffect(() => {
    getClientBenefitsCategories({
      variables: { clientId },
    })
  }, [])
  const redirectToNextSteps = (event) => {
    event.preventDefault()
    // check toggle and redirect to '/next-steps'
    if (isNextSteps) {
      return debouncedNavigate('/next-steps')
    }
    dispatch(parseQuery(zipCode))
    debouncedNavigate('/locations/list')
  }
  const benefitsCategories = categoriesSelector(data)

  const [tabIndex, setTabIndex] = useState(0)

  const totalBenefitAmount = calculateTotalBenefitAmount(data)
  const screeningResultsValues =
    data?.calculateScreeningResults?.screeningResults?.benefitTotals

  const handleTabChange = (event, index) => {
    setTabIndex(index)
  }

  const filterPrograms = (programs) => {
    return programs.filter((program) => {
      if (tabIndex === 0) return program.eligible
      else if (tabIndex === 1) return !program.eligible
      else return program
    })
  }
  const renderPrograms = (programs, categoryIconPath, category) => {
    return filterPrograms(programs)
      .sort(sortByKey('name'))
      .map((program, index) => {
        return (
          <Col md='6' key={index + program.id}>
            <EligibilityBenefitListItem
              {...program}
              category={category}
              categoryIcon={renderCategoryIcon(categoryIconPath)}
            />
          </Col>
        )
      })
  }

  const renderLocationBtn = () => {
    return (
      <StyledDynamicCtaButton
        component={Link}
        to={'/locations/list'}
        onClick={redirectToNextSteps}
        background={singleStopGreen}
        textshadow='none'
        border={'0.0625rem solid ' + singleStopGreen}
        margin='0'
        padding={isTabletAndMobile ? '2rem' : '1rem 0'}
        fontSize={isTabletAndMobile ? '0.8125rem' : '0.6875rem'}
        width={isTabletAndMobile ? '100%' : '75%'}
        height={isTabletAndMobile ? '4rem' : '3rem'}
        justcont={isTabletAndMobile ? 'flex-start' : ''}
        borderhover={'0.0625rem solid ' + singleStopGreen}
        $nextSteps={true}
        role='link'
      >
        Next Steps
        <Icon
          position={'absolute'}
          right={'1rem'}
          fill={'#FFFFFF'}
          margin={'0'}
          scale={isTabletAndMobile ? '1.375rem' : '0.8125rem'}
          viewBox={'0 0 24 24'}
          cursorOnHover={'pointer'}
          rotate={'true'}
        >
          {backIconPath}
        </Icon>
      </StyledDynamicCtaButton>
    )
  }

  const isTabletAndMobile = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })
  return (
    <MuiThemeProvider theme={ssTheme}>
      <StyledAppBar position='static'>
        <Container>
          <StyledMuiTextFieldWrapper mbottom='0'>
            <Row alignItems='center'>
              <Col lg='3'>
                <StyledMainHeading
                  $eligibility={true}
                  id='mainContent'
                  tabIndex='-1'
                >
                  Here Are Your Results
                </StyledMainHeading>
              </Col>
              <Col lg='6'>
                <EligibilityBenefitsTabs
                  onChange={handleTabChange}
                  value={tabIndex}
                />
              </Col>
              {!isTabletAndMobile && (
                <Col md='3'>
                  <StyledMuiTextFieldWrapper
                    width='100%'
                    mbottom='0'
                    mtop={'2rem'}
                    display='flex'
                    justCont='flex-end'
                  >
                    {renderLocationBtn()}
                  </StyledMuiTextFieldWrapper>
                </Col>
              )}
            </Row>
          </StyledMuiTextFieldWrapper>
        </Container>
      </StyledAppBar>
      <Container
        role='tabpanel'
        aria-labelledby={`tab${tabIndex + 1}`}
        id={`tab_${tabIndex}`}
      >
        <NextStepsFriendlyText
          benefitsCategories={benefitsCategories}
          tabIndex={tabIndex}
          totalBenefitAmount={totalBenefitAmount}
          loading={loading}
          screeningResults={screeningResultsValues}
        />
        <Box p={0}>
          <StyledTabPanelDiv>
            <StyledList>
              {benefitsCategories
                .sort(sortByKey('category'))
                .map((benefitsCategory, index) => {
                  let { category, categoryIconPath, programs } =
                    benefitsCategory
                  return filterPrograms(programs).length ? (
                    <StyledListItem>
                      <StyledMuiTextFieldWrapper
                        data-testid='benefit'
                        background='#fff'
                        mbottom='1.5rem'
                        padding='1rem 3rem'
                        tabPadd='1rem 3rem'
                        shadow='0px 0.1875rem 0.375rem #1D283117'
                        radius='0.4375rem'
                        key={index}
                        role='group'
                        aria-label='benefit'
                      >
                        <Row>
                          <Col md='4'>
                            <StyledHeading categoryTitle={true}>
                              {renderCategoryIcon(categoryIconPath)}
                              {category}
                            </StyledHeading>
                          </Col>
                          <Col md='8'>
                            <Row>
                              {renderPrograms(
                                programs,
                                categoryIconPath,
                                category
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </StyledMuiTextFieldWrapper>
                    </StyledListItem>
                  ) : null
                })}
            </StyledList>
          </StyledTabPanelDiv>
        </Box>
      </Container>
      {isTabletAndMobile && (
        <StyledMuiTextFieldWrapper
          style={{ marginBottom: '0' }}
          mbottom='0'
          display='flex'
          justCont='center'
          alignitems='center'
          position='fixed'
          bottom='0'
          left='0'
          background='#fff'
          padding='0rem 0rem'
          width='100%'
        >
          {renderLocationBtn()}
        </StyledMuiTextFieldWrapper>
      )}
    </MuiThemeProvider>
  )
}

export default Eligibility
