import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'
import { useApolloClient, useMutation } from '@apollo/client'
import { compose } from '@reduxjs/toolkit'
import { StyledTermsAndButtonToContinueWrapper } from 'Components/PreScreener/style'
import {
  ReverseCol,
  StyledHeadingMain,
  StyledMainHeading,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
  StyledParentPreScreenerButton,
  StyledScreeningCompleteSvg,
} from 'Components/Screener/style'
import HeaderWithContainerAndCard from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerAndCard'
import useIsFeatureToggleOn from 'Components/Shared/Hooks/useIsFeatureToggleOn'
import ModalError from 'Components/Shared/Modals/ModalError'
import moment from 'moment'
import { CALCULATE_SCREENING_RESULTS } from 'Pages/Results/gql'
import ScreeningCompleteSvg from 'Pages/ScreenerCompleted/ScreenerCompleted.svg'
import ScreenerCompletedPrimaryButton from 'Pages/ScreenerCompleted/ScreenerCompletedPrimaryButton'
import {
  StyledButtonFilled,
  StyledStyledButtonFilledParent,
} from 'Shared/Buttons/style'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { Container } from 'styled-bootstrap-grid'

const ScreenerCompleted = ({ client }) => {
  const navigate = useNavigate()
  const { screenerCompleted, editing, id } = client
  const [isReturningUser, setIsReturningUser] = useState(false)
  const [error, setError] = useState('')
  const isFeatureToggleOn = useIsFeatureToggleOn()
  const [calculateScreeningResults] = useMutation(CALCULATE_SCREENING_RESULTS)
  const apolloClient = useApolloClient()

  useEffect(() => {
    if (!screenerCompleted || editing) {
      navigate('/screening')
    }
  }, [navigate, screenerCompleted, editing])

  useEffect(() => {
    if (screenerCompleted) {
      const numberOfDaysSinceScreeningCompleted = moment().diff(
        moment(screenerCompleted),
        'days'
      )
      if (isFeatureToggleOn('update_eligibility_button')) {
        if (numberOfDaysSinceScreeningCompleted) {
          setIsReturningUser(true)
        }
      }
    }

    calculateScreeningResults({
      variables: { clientId: id },
    }).then(() => {
      apolloClient.cache.evict({ id: `Client:${id}` })
    })
  }, [])

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  return (
    <>
      <HeaderWithContainerAndCard>
        <Container>
          <StyledMainHeading
            id='mainContent'
            tabIndex='-1'
            $textCenter={true}
            $screenerCompleted={true}
          >
            Congratulations
          </StyledMainHeading>

          <StyledScreeningCompleteSvg alt='' src={ScreeningCompleteSvg} />
          <StyledHeadingMain
            fontSize='1.5625rem'
            textalign='center'
            marginTop='.5rem'
            marginBottom='.5rem'
          >
            your screening is now completed!
          </StyledHeadingMain>

          <StyledParagraph
            textalign='center'
            fontSize='1rem'
            marginBottom='2.25rem'
          >
            If you'd like to, you can review your responses before moving
            forward.
          </StyledParagraph>

          {isMobile ? (
            <StyledParentButtonsActionsInMobile>
              <ReverseCol>
                <StyledParentPreScreenerButton width='100%'>
                  <StyledButtonFilled
                    disableFocusRipple={true}
                    variant='outlined'
                    component={Link}
                    to={'/review'}
                  >
                    review your responses
                  </StyledButtonFilled>
                </StyledParentPreScreenerButton>
                <StyledParentPreScreenerButton width='100%'>
                  <ScreenerCompletedPrimaryButton
                    clientId={id}
                    isReturningUser={isReturningUser}
                    setError={setError}
                  />
                </StyledParentPreScreenerButton>
              </ReverseCol>
            </StyledParentButtonsActionsInMobile>
          ) : (
            <StyledTermsAndButtonToContinueWrapper width='50%' margin='auto'>
              <ScreenerCompletedPrimaryButton
                clientId={id}
                isReturningUser={isReturningUser}
                setError={setError}
              />
              <StyledStyledButtonFilledParent>
                <StyledButtonFilled
                  disableFocusRipple={true}
                  variant='outlined'
                  component={Link}
                  to={'/review'}
                >
                  review your responses
                </StyledButtonFilled>
              </StyledStyledButtonFilledParent>
            </StyledTermsAndButtonToContinueWrapper>
          )}
          {error && <ModalError type={'error'} message={error} />}
        </Container>
      </HeaderWithContainerAndCard>
    </>
  )
}

const mapStateToProps = (state) => {
  const { client } = state

  return {
    client,
  }
}

export default compose(connect(mapStateToProps))(ScreenerCompleted)
