import { gql } from '__generated__/gql'

export const GET_CLIENTS = gql(`
  query householdDemographicsGrid(
    $pageSize: Int
    $pageNumber: Int
    $sort: [ClientLocationSortInput!]
    $filter: ClientLocationFilter
  ) {
    clientLocations(
      pageSize: $pageSize
      pageNumber: $pageNumber
      sort: $sort
      filter: $filter
    ) {
      totalCount
      pageCount
      nodes {
        id
        closedReason
        displayClosedReason @client
status
        displayClientRecordStatus @client
        displayAssignedTo @client
        caseManager {
          id
          fullName
        }
        client {
          customFieldResponses {
            id
            value
            customField {
              id
              label
            }
          }
          id
          firstName
          lastName
          nickName
          email
          zipCode
          household {
            situation
            size
            id
          }
          primaryPhone
          alternatePhone
          alternateEmail
          screening {
            id
            status
            displayStatus @client
            isAssisted
            displayScreeningType @client
          }
          displayName @client
          displayContactInformation @client
          displayAlternatePhone @client
          demographic {
            isVeteran
            displayIsVeteran @client
            militaryDischargeStatus
            displayMilitaryDischargeStatus @client
            militaryBranch
            displayMilitaryBranch @client
            militaryTimeServed
            displayMilitaryStartDate @client
            displayMilitaryEndDate @client
          }
          householdDemographicReport {
            assistedBy
            needs
            adultCareExpensesAmount
            displayAdultCareExpensesAmount @client
            adultMembersCount
            childSupportExpensesAmount
            displayChildSupportExpensesAmount @client
            childcareExpensesAmount
            displayChildcareExpensesAmount @client
            childrenMembersCount
            currentlyWorkingCount
            disabledCount
            hasEducationExpenses
            hasUtilityExpenses
            displayHasEducationExpenses @client
            displayHasUtilityExpenses @client
            healthInsuredCount
            outOfPocketMedicalExpensesAmount
            displayOutOfPocketMedicalExpensesAmount @client
            pregnantCount
            rentOrMortgageExpensesAmount
            displayRentOrMortgageExpensesAmount @client
            seniorMembersCount
            studentCount
            totalAdditionalIncome
            displayTotalAdditionalIncome @client
            totalAnnualIncome
            displayTotalAnnualIncome @client
            totalMembersCount
            totalSavingsAmount
            displayTotalSavingsAmount @client
            veteranCount
          }
          physicalAddress {
            state
          }
        }
        location {
          name
          customFields {
            id
            label
          }
        }
        updatedAt
        displayUpdatedAt @client
        createdAt
        displayCreatedAt @client
      }
    }
 }`)

export const GET_REPORTING_GRID_SETTINGS = gql(`
  query GetReportingGridSettings {
    reportingGridQueryVariables @client {
      household {
        filter {
          screeningStatus
          status
          screeningType
          searchTerm
          locationIds
          createdAt {
            min
            max
          }
        }
        skip
        sort {
          field
          direction
        }
      }
      individual {
        filter {
          memberType
          locationIds
          createdAt {
            min
            max
          }
          screeningStatus
        }
        searchTerm
        skip
        sort {
          field
          direction
        }
      }
      cases {
        filter {
          activeStatus
          categories
          caseManagerId
          serviceId
          caseStatus
          locationIds
          closed
          createdAt {
            min
            max
          }
        }
        searchTerm
        skip
        sort {
          field
          direction
        }
      }
      activities {
        filter {
          activityType
          serviceId
          caseManagerId
          addedBy
          locationIds
          createdAt {
            min
            max
          }
        }
        searchTerm
        skip
        sort {
          field
          direction
        }
      }
      drawdown {
        filter {
          locationIds
          serviceIds
          dateRange {
            min
            max
          }
        }
        searchTerm
      }
      notes {
        filter {
          caseManagerId
          locationIds
          createdAt {
            min
            max
          }
        }
        searchTerm
        skip
        sort {
          field
          direction
        }
      }
    }
 }`)

export const GET_FILTER_SELECT_STATE = gql(`
  query GetFilterSelectState {
    reportingFilters @client {
      grid {
        household {
          screeningStatus
          status
          screeningType
          searchTerm
          locationIds
          createdAt {
            min
            max
          }
        }
        individual {
          memberType
          locationIds
          screeningStatus
          createdAt {
            min
            max
          }
        }
        cases {
          activeStatus
          categories
          caseManagerId
          serviceId
          caseStatus
          locationIds
          closed
          createdAt {
            min
            max
          }
        }
        activities {
          activityType
          serviceId
          caseManagerId
          addedBy
          locationIds
          createdAt {
            min
            max
          }
        }
        drawdown {
          locationIds
          serviceIds
          dateRange {
            min
            max
          }
        }
        notes {
          caseManagerId
          locationIds
          createdAt {
            min
            max
          }
        }
      }
      dashboard {
        demographics {
          dateRange {
            min
            max
          }
          locationIds
          state
          householdScreeningChartData
          singleIndividualDemographicsChart {
            demographicType
          }
          demographicVerticalBarChart {
            demographicOne
            demographicTwo
          }
          screeningDetails {
            screeningType
          }
        }
        analytics {
          locationIds
          dateRange {
            min
            max
          }
          outcomeServiceCaseChart {
            serviceId
          }
          serviceCasesByTypeChart {
            outcomeType
          }
          confirmedCasesTimeseriesChart {
            serviceId
          }
        }
        kpi {
          dateRange {
            min
            max
          }
          locationIds
          state
        }
      }
    }
 }`)
export const GET_REPORTING_MODAL_STATE = gql(`
  query GetReportingModalState {
    showReportingMainModal @client
 }`)
export const GET_INDIVIDUAL_DEMOGRAPHICS = gql(`
  query getDemographics(
    $pageSize: Int
    $pageNumber: Int
    $sort: [IndividualDemographicReportSortInput!]
    $filter: IndividualDemographicReportFilterInput
    $searchTerm: String
  ) {
    individualDemographicReport(
      pageSize: $pageSize
      pageNumber: $pageNumber
      sort: $sort
      filter: $filter
      searchTerm: $searchTerm
    ) {
      totalCount
      pageCount
      nodes {
        householdId
        id
        fullName
        annualIncome
        displayAnnualIncome @client
        age
        dateOfBirth
        displayDateOfBirth @client
        relationshipToClient
        employmentCount
        isCurrentlyWorking
        displayIsCurrentlyWorking @client
        employmentStatus
        additionalIncome
        displayAdditionalIncome @client
        alimonyAmount
        displayAlimonyAmount @client
        primaryAccountHolder {
          fullName
          email
          primaryPhone
          alternatePhone
          alternateEmail
          displayContactInformation @client
          displayAlternatePhone @client
          clientLocations {
            id
          }
          lastYearAdjustedGrossIncome
          taxFilingStatus
          displayLastYearAdjustedGrossIncome @client
          displayTaxFilingStatus @client
        }
        demographic {
          id
          gender
          race
          ethnicity
          education
          displayEducation @client
          healthInsurance
          hasHealthInsurance
          displayHasHealthInsurance @client
          isStudent
          displayIsStudent @client
          isVeteran
          displayIsVeteran @client
          militaryDischargeStatus
          displayMilitaryDischargeStatus @client
          militaryBranch
          displayMilitaryBranch @client
          militaryTimeServed
          displayMilitaryStartDate @client
          displayMilitaryEndDate @client
          isDisabled
          pregnancyStatus
          isUsCitizen
          displayIsUsCitizen @client
          immigrationStatus
          lengthOfPermanentResidency
          courseLoad
          hasWorkStudy
          displayHasWorkStudy @client
          isEligibleForWorkStudy
          displayIsEligibleForWorkStudy @client
          expectedFamilyContribution
          costOfAttendance
          displayEfc @client
          displayCoa @client
        }
        childSupportAmount
        displayChildSupportAmount @client
        pensionAmount
        displayPensionAmount @client
        ssdSsiAmount
        displaySsdSsiAmount @client
        unemploymentInsuranceAmount
        displayUnemploymentInsuranceAmount @client
        vaBenefitsAmount
        displayVaBenefitsAmount @client
        workersCompensationAmount
        displayWorkersCompensationAmount @client
        otherAdditionalIncomeAmount
        displayOtherAdditionalIncomeAmount @client
        savingsAmount
        displaySavingsAmount @client
        displaySavings @client
        claimedAsDependent
        displayClaimedAsDependent @client
        site {
          id
          name
        }
      }
    }
 }`)

export const ACTIVITY_REPORT = gql(`
  query ActivityReport(
    $pageSize: Int
    $pageNumber: Int
    $sort: [ActivityReportSortInput!]
    $searchTerm: String
    $filter: ActivityReportFilterInput
  ) {
    activityReport(
      pageSize: $pageSize
      pageNumber: $pageNumber
      sort: $sort
      searchTerm: $searchTerm
      filter: $filter
    ) {
      totalCount
      pageCount
      nodes {
        id
        site
        servicedPeople
        category
        addedBy
        caseName
        primaryHouseholdMember
        householdId
        activityDate
        activityType
        applicationType
        referralType
        displayApplicationType @client
        displayReferralType @client
        outcomeType
        displayOutcomeType @client
        confirmationType
        confirmedAmount
        displayConfirmedAmount @client
        denialReason
        displayConfirmationType @client
        displayDenialReason @client
        displayActivityType @client
        clientLocation {
          id
        }
        summary
        createdAt
        updatedAt
        referralLocationName
        displayCreatedAt @client
        displayUpdatedAt @client
        body
        drawdownAmount
        displayDrawdownAmount @client
        client {
          id
          email
          primaryPhone
          alternatePhone
          alternateEmail
          displayContactInformation @client
          displayAlternatePhone @client
        }
        note {
          id
          summary
          body
          activityDate
          noteType
          applicationType
          referralType
          outcomeType
          confirmationType
          denialReason
          outcomeAmount
          serviceCase {
            id
            people {
              firstName
              lastName
              ... on HouseholdMember {
                id
              }
              ... on Client {
                id
                nickName
                __typename
              }
              __typename
            }
            service {
              id
              name
              __typename
            }
            __typename
          }
          attachments {
            ...AttachmentFields
            __typename
          }
        }
      }
    }
 }`)
export const GET_SERVICE_CASES = gql(`
  query caseReport(
    $pageSize: Int
    $pageNumber: Int
    $filter: CaseReportFilterInput
    $searchTerm: String
    $sort: [CaseReportSortInput!]
  ) {
    caseReport(
      pageSize: $pageSize
      pageNumber: $pageNumber
      filter: $filter
      searchTerm: $searchTerm
      sort: $sort
    ) {
      totalCount
      nodes {
        closed
        drawdownAmount
        displayDrawdownAmount @client
        eligibility
        displayEligibility @client
        estimatedAmount
        displayEstimatedAmount @client
        id
        caseStatus
        displayCaseStatus @client
        displayState @client
        createdAt
        displayCreatedAt @client
        updatedAt
        displayUpdatedAt @client
        servicedPeople
        caseName
        category
        closedReason
        displayClosedReason @client
        assignedTo
        householdId
        primaryHouseholdMember
        site
        outcomeDate
        displayOutcomeDate @client
        confirmedAmount
        displayConfirmedAmount @client
        denialReason
        displayDenialReason @client
        outcomeType
        displayOutcomeType @client
        clientLocation {
          id
        }
        client {
          id
          email
          primaryPhone
          alternatePhone
          alternateEmail
          displayContactInformation @client
          displayAlternatePhone @client
        }
      }
    }
 }`)
export const GET_COLUMN_SELECTIONS = gql(`
  query ColumnSelections {
    columnSelections @client {
      household
      individual
      cases
      activities
    }
 }`)

export const GET_LOCATION_SERVICES = gql(`
  query getLocationServices($locationIds: [ID!]) {
    me {
      id
      userable {
        ... on CaseManager {
          id
          filterable(locationIds: $locationIds) {
            services {
              id
              name
            }
          }
        }
      }
    }
 }`)

export const DOWNLOAD_REPORT_FIELDS = gql(`
  fragment downloadReportFields on DownloadReport {
    id
    status
    errorLog
    fileSize
    mimeType
    completedAt
    createdAt
    query
    caseManager {
      id
      fullName
    }
    downloadLink {
      headers
      url
    }
 }`)

export const CREATE_CASE_REPORT = gql(`
  mutation createCaseReport(
    $searchTerm: String
    $filter: CaseReportFilterInput
  ) {
    caseReport(searchTerm: $searchTerm, filter: $filter) {
      downloadReport {
        ...downloadReportFields
      }
    }
  }
`)

export const CREATE_HOUSEHOLD_REPORT = gql(`
  mutation createHouseholdReport($filter: ClientLocationFilter) {
    clientLocationsReport(filter: $filter) {
      downloadReport {
        ...downloadReportFields
      }
    }
  }
`)

export const CREATE_INDIVIDUAL_REPORT = gql(`
  mutation createIndividualReport(
    $filter: IndividualDemographicReportFilterInput
    $searchTerm: String
  ) {
    individualDemographicReport(filter: $filter, searchTerm: $searchTerm) {
      downloadReport {
        ...downloadReportFields
      }
    }
  }
`)

export const CREATE_ACTIVITIES_REPORT = gql(`
  mutation createActivitiesReport(
    $filter: ActivityReportFilterInput
    $searchTerm: String
  ) {
    activityReport(filter: $filter, searchTerm: $searchTerm) {
      downloadReport {
        ...downloadReportFields
      }
    }
  }
`)
export const CREATE_DRAWDOWN_REPORT = gql(`
  mutation drawdownByCaseReportMutation(
    $filter: DrawdownByCaseReportFilterInput
    $format: ReportFormat
  ) {
    drawdownByCaseReport(filter: $filter, format: $format) {
      downloadReport {
        ...downloadReportFields
      }
    }
  }
`)

export const CREATE_NOTES_REPORT = gql(`
  mutation generalNotesReport(
    $filter: GeneralNotesReportFilterInput
    $format: ReportFormat
  ) {
    generalNotesReport(filter: $filter, format: $format) {
      downloadReport {
        ...downloadReportFields
      }
    }
  }
`)

export const GET_REPORT_CREATION_STATUS_BY_ID = gql(`
  query getReportStatusFromMe($id: ID) {
    me {
      userable {
        ... on CaseManager {
          id
          fullName
          downloadReports(id: $id) {
            ...downloadReportFields
          }
        }
      }
    }
  }
`)

// DEMOGRAPHICS DASHBOARD
// "Quick Details" card
// #5 from top left to bottom right
export const GET_DEMOGRAPHIC_QUICK_DETAILS = gql(`
  query getDemographicQuickDetails(
    $dateRange: DateTimeRangeInput
    $state: String
    $filter: DemographicsFilterInput
  ) {
    demographicQuickDetails(
      dateRange: $dateRange
      state: $state
      filter: $filter
    ) {
      averageHouseholdIncome
      averageHouseholdSize
      averageIndividualAge
      totalIndividuals
      averageHouseholdMonthlyExpenses
      averageHouseholdChildCount
    }
 }`)

// DEMOGRAPHICS DASHBOARD
// "Individual Demographics By" stacked vertical bar chart with two filters
// #4 from top left to bottom right
export const GET_DEMOGRAPHIC_VERTICAL_BAR_CHART = gql(`
  query getDemographicVerticalBarChart(
    $dateRange: DateTimeRangeInput
    $demographicOne: IndividualDemographicChartType
    $demographicTwo: IndividualDemographicChartType
    $filter: IndividualDemographicReportFilterInput
  ) {
    demographicVerticalBarChart(
      dateRange: $dateRange
      demographicOne: $demographicOne
      demographicTwo: $demographicTwo
      filter: $filter
    ) {
      victoryBarData {
        dataSet {
          x
          y
        }
        name
      }
    }
 }`)

// DEMOGRAPHICS DASHBOARD
// "Household" ring chart (assisted /self-screened)
// #1 from top left to bottom right
export const GET_HOUSEHOLD_SCREENING_CHART_DATA = gql(`
  query getHouseholdScreeningChartData($filter: ScreeningFilterInput) {
    householdScreeningChartData(filter: $filter) {
      assisted
      selfScreened
    }
 }`)
// PROGRAM ANALYTICS
// "Cases By Outcome"
// #2 from top top left to bottom right
export const GET_OUTCOME_SERVICE_CASE_CHART = gql(`
  query getOutcomeServiceCaseChart(
    $dateRange: DateTimeRangeInput
    $filter: ServiceCaseReportsFilterInput
  ) {
    outcomeServiceCaseChart(dateRange: $dateRange, filter: $filter) {
      confirmedCount
      confirmedPercentage
      deniedCount
      deniedPercentage
      inactiveCount
      inactivePercentage
      pendingCount
      pendingPercentage
      total
    }
 }`)
// PROGRAM ANALYTICS
// "Quick Details"
// #5 from top left to bottom right
export const GET_PROGRAM_ANALYTICS_QUICK_DETAILS = gql(`
  query getProgramAnalyticsQuickDetails(
    $dateRange: DateTimeRangeInput
    $state: String
    $filter: ServiceCaseReportsFilterInput
  ) {
    programAnalyticsQuickDetails(
      dateRange: $dateRange
      state: $state
      filter: $filter
    ) {
      casesWithOutcomesCount
      casesWithPositiveOutcomesCount
      referralsCount
      submissionsCount
    }
 }`)

// DEMOGRAPHICS DASHBOARD
// "Screening Details" horizontal bar chart
// #2 from top left to bottom right
export const GET_SCREENING_DETAILS_CHART_DATA = gql(`
  query getScreeningDetailsChartData(
    $dateRange: DateTimeRangeInput
    $screeningType: ScreeningIntakeMethod
    $state: String
    $filter: ScreeningsFilterInput
  ) {
    screeningDetailsChartData(
      dateRange: $dateRange
      screeningType: $screeningType
      state: $state
      filter: $filter
    ) {
      bypassedCount
      bypassedPercentage
      completedCount
      completedPercentage
      incompleteCount
      incompletePercentage
      totalInSet
    }
 }`)
// ANALYTICS DASHBOARD
// "Case By Type"
// #3 from top left to bottom right
export const GET_SERVICE_CASES_BY_TYPE_CHART = gql(`
  query getServiceCasesByTypeChart(
    $dateRange: DateTimeRangeInput
    $outcomeType: ServiceCaseOutcomeType
    $screeningType: ScreeningIntakeMethod
    $state: String
    $top: Int
    $filter: ServiceCaseReportsFilterInput
  ) {
    serviceCasesByTypeChart(
      dateRange: $dateRange
      outcomeType: $outcomeType
      screeningType: $screeningType
      state: $state
      top: $top
      filter: $filter
    ) {
      allCircleData {
        x
        y
      }
      topResultsCircleData {
        x
        y
      }
      total
    }
 }`)
// DEMOGRAPHICS DASHBOARD
// "Individual Demographics By" ring chart
// #3 from top left to bottom right
export const GET_SINGLE_INDIVIDUAL_DEMOGRAPHICS_CHART = gql(`
  query getSingleIndividualDemographicsChart(
    $dateRange: DateTimeRangeInput
    $demographicType: IndividualDemographicChartType
    $state: String
    $filter: IndividualDemographicReportFilterInput
  ) {
    singleIndividualDemographicsChart(
      dateRange: $dateRange
      demographicType: $demographicType
      state: $state
      filter: $filter
    ) {
      courseLoadChart {
        fullTimeCount
        fullTimePercentage
        halfTimeCount
        halfTimePercentage
        lessThanHalfTimeCount
        lessThanHalfTimePercentage
        notAvailableCount
        notAvailablePercentage
        total
      }
      educationChart {
        academicDoctorateDegreeCount
        academicDoctorateDegreePercentage
        associatesDegreeCount
        associatesDegreePercentage
        bachelorsDegreeCount
        bachelorsDegreePercentage
        fifthGradeOrBelowCount
        fifthGradeOrBelowPercentage
        gedOrEquivalentCount
        gedOrEquivalentPercentage
        highSchoolGraduateCount
        highSchoolGraduatePercentage
        mastersDegreeCount
        mastersDegreePercentage
        ninthToTwelfthGradeCount
        ninthToTwelfthGradePercentage
        notAvailableCount
        notAvailablePercentage
        preferNotToAnswerCount
        preferNotToAnswerPercentage
        professionalDoctorateDegreeCount
        professionalDoctorateDegreePercentage
        sixthToEighthGradeCount
        sixthToEighthGradePercentage
        someCollegeNoDegreeCount
        someCollegeNoDegreePercentage
        technicalOrOccupationalCertificatePercentage
        technicalOrOccupationalCertificateCount
        total
      }
      ethnicityChart {
        hispanicCount
        hispanicPercentage
        nonHispanicCount
        nonHispanicPercentage
        notAvailableCount
        notAvailablePercentage
        preferNotToAnswerCount
        preferNotToAnswerPercentage
        total
      }
      genderChart {
        femaleCount
        femalePercentage
        maleCount
        malePercentage
        transgenderWomanCount
        transgenderWomanPercentage
        transgenderManCount
        transgenderManPercentage
        nonBinaryCount
        nonBinaryPercentage
        notAvailableCount
        notAvailablePercentage
        notListedCount
        notListedPercentage
        preferNotToAnswerCount
        preferNotToAnswerPercentage
        total
      }
      healthInsuranceChart {
        employerBasedHealthInsuranceCount
        employerBasedHealthInsurancePercentage
        iDontKnowCount
        iDontKnowPercentage
        medicaidChipCount
        medicaidChipPercentage
        medicareCount
        medicarePercentage
        notAvailableCount
        notAvailablePercentage
        otherPublicHealthInsuranceCount
        otherPublicHealthInsurancePercentage
        privateHealthInsuranceOffMarketplaceCount
        privateHealthInsuranceOffMarketplacePercentage
        privateHealthInsuranceThroughTheMarketplaceCount
        privateHealthInsuranceThroughTheMarketplacePercentage
        total
        vaHealthcareOrTricareCount
        vaHealthcareOrTricarePercentage
      }
      immigrationStatusChart {
        alienActiveMilitaryCount
        alienActiveMilitaryPercentage
        notAvailableCount
        notAvailablePercentage
        otherCount
        otherPercentage
        permanentResidentCount
        permanentResidentPercentage
        preferNotToAnswerCount
        preferNotToAnswerPercentage
        temporaryImmigrationStatusCount
        temporaryImmigrationStatusPercentage
        total
      }
      lengthOfPermanentResidencyChart {
        fiveOrMoreCount
        fiveOrMorePercentage
        fourCount
        fourPercentage
        notAvailableCount
        notAvailablePercentage
        oneCount
        onePercentage
        threeCount
        threePercentage
        total
        twoCount
        twoCount
      }
      raceChart {
        americanIndianOrAlaskanNativeCount
        americanIndianOrAlaskanNativePercentage
        asianCount
        asianPercentage
        blackOrAfricanAmericanCount
        blackOrAfricanAmericanPercentage
        fromMultipleRacesCount
        fromMultipleRacesPercentage
        nativeHawaiianOrOtherPacificIslanderCount
        nativeHawaiianOrOtherPacificIslanderPercentage
        notAvailableCount
        notAvailablePercentage
        otherCount
        otherPercentage
        preferNotToAnswerCount
        preferNotToAnswerPercentage
        total
        whiteCount
        whitePercentage
      }
    }
 }`)
// PROGRAM ANALYTICS CHART
// CHART #4 (top left to bottom right)
export const GET_CONFIRMED_CASES_TIMESERIES_CHART = gql(`
  query GetConfirmedCasesTimeseriesChart(
    $dateRange: DateTimeRangeInput
    $filter: ServiceCaseReportsFilterInput
  ) {
    confirmedCasesTimeseriesChart(
      dateRange: $dateRange
      filter: $filter
    ) {
      groupedBy
      timeSeries {
        x
        y
      }
    }
 }`)

export const DEMOGRAPHICS_DASHBOARD = gql(`
  query demographicsDashboard(
    $dateRange: DateTimeRangeInput
    $demographicOne: IndividualDemographicChartType
    $demographicTwo: IndividualDemographicChartType
    $demographicType: IndividualDemographicChartType
    $screeningType: ScreeningIntakeMethod
  ) {
    demographicQuickDetails(dateRange: $dateRange) {
      averageHouseholdIncome
      averageHouseholdSize
      averageIndividualAge
      totalIndividuals
    }
    demographicVerticalBarChart(
      dateRange: $dateRange
      demographicOne: $demographicOne
      demographicTwo: $demographicTwo
    ) {
      victoryBarData {
        dataSet {
          x
          y
        }
        name
      }
    }
    householdScreeningChartData {
      assisted
      selfScreened
    }
    screeningDetailsChartData(
      dateRange: $dateRange
      screeningType: $screeningType
    ) {
      bypassedCount
      bypassedPercentage
      completedCount
      completedPercentage
      incompleteCount
      incompletePercentage
      totalInSet
    }
    singleIndividualDemographicsChart(
      dateRange: $dateRange
      demographicType: $demographicType
      screeningType: $screeningType
    ) {
      courseLoadChart {
        fullTimeCount
        fullTimePercentage
        halfTimeCount
        halfTimePercentage
        lessThanHalfTimeCount
        lessThanHalfTimePercentage
        notAvailableCount
        notAvailablePercentage
        total
      }
      educationChart {
        academicDoctorateDegreeCount
        academicDoctorateDegreePercentage
        associatesDegreeCount
        associatesDegreePercentage
        bachelorsDegreeCount
        bachelorsDegreePercentage
        fifthGradeOrBelowCount
        fifthGradeOrBelowPercentage
        gedOrEquivalentCount
        gedOrEquivalentPercentage
        highSchoolGraduateCount
        highSchoolGraduatePercentage
        mastersDegreeCount
        mastersDegreePercentage
        ninthToTwelfthGradeCount
        ninthToTwelfthGradePercentage
        notAvailableCount
        notAvailablePercentage
        preferNotToAnswerCount
        preferNotToAnswerPercentage
        professionalDoctorateDegreeCount
        professionalDoctorateDegreePercentage
        sixthToEighthGradeCount
        sixthToEighthGradePercentage
        someCollegeNoDegreeCount
        someCollegeNoDegreePercentage
        total
      }
      ethnicityChart {
        hispanicCount
        hispanicPercentage
        nonHispanicCount
        nonHispanicPercentage
        notAvailableCount
        notAvailablePercentage
        preferNotToAnswerCount
        preferNotToAnswerPercentage
        total
      }
      genderChart {
        femaleCount
        femalePercentage
        maleCount
        malePercentage
        transgenderWomanCount
        transgenderWomanPercentage
        transgenderManCount
        transgenderManPercentage
        nonBinaryCount
        nonBinaryPercentage
        notAvailableCount
        notAvailablePercentage
        notListedCount
        notListedPercentage
        preferNotToAnswerCount
        preferNotToAnswerPercentage
        total
      }
      healthInsuranceChart {
        employerBasedHealthInsuranceCount
        employerBasedHealthInsurancePercentage
        iDontKnowCount
        iDontKnowPercentage
        medicaidChipCount
        medicaidChipPercentage
        medicareCount
        medicarePercentage
        notAvailableCount
        notAvailablePercentage
        otherPublicHealthInsuranceCount
        otherPublicHealthInsurancePercentage
        privateHealthInsuranceOffMarketplaceCount
        privateHealthInsuranceOffMarketplacePercentage
        privateHealthInsuranceThroughTheMarketplaceCount
        privateHealthInsuranceThroughTheMarketplacePercentage
        total
        vaHealthcareOrTricareCount
        vaHealthcareOrTricarePercentage
      }
      immigrationStatusChart {
        alienActiveMilitaryCount
        alienActiveMilitaryPercentage
        notAvailableCount
        notAvailablePercentage
        otherCount
        otherPercentage
        permanentResidentCount
        permanentResidentPercentage
        preferNotToAnswerCount
        preferNotToAnswerPercentage
        temporaryImmigrationStatusCount
        temporaryImmigrationStatusPercentage
        total
      }
      lengthOfPermanentResidencyChart {
        fiveOrMoreCount
        fiveOrMorePercentage
        fourCount
        fourPercentage
        notAvailableCount
        notAvailablePercentage
        oneCount
        onePercentage
        threeCount
        threePercentage
        total
        twoCount
        twoCount
      }
      raceChart {
        americanIndianOrAlaskanNativeCount
        americanIndianOrAlaskanNativePercentage
        asianCount
        asianPercentage
        blackOrAfricanAmericanCount
        blackOrAfricanAmericanPercentage
        fromMultipleRacesCount
        fromMultipleRacesPercentage
        nativeHawaiianOrOtherPacificIslanderCount
        nativeHawaiianOrOtherPacificIslanderPercentage
        notAvailableCount
        notAvailablePercentage
        otherCount
        otherPercentage
        preferNotToAnswerCount
        preferNotToAnswerPercentage
        total
        whiteCount
        whitePercentage
      }
    }
 }`)

export const GET_DASHBOARD_QUERY_VARIABLES = gql(`
  query getDashboardQueryVariables {
    dashboardQueryVariables @client {
      demographicQuickDetails {
        dateRange {
          min
          max
        }
        locationIds
        state
      }
      demographicVerticalBarChart {
        dateRange {
          min
          max
        }
        locationIds
        state
        demographicOne
        demographicTwo
      }
      householdScreeningChartData {
        filter {
          createdAt {
            min
            max
          }
          locationIds
        }
      }
      screeningDetails {
        dateRange {
          min
          max
        }
        locationIds
        state
        screeningType
      }
      singleIndividualDemographicsChart {
        dateRange {
          min
          max
        }
        locationIds
        state
        demographicType
      }
      outcomeServiceCaseChart {
        locationIds
        dateRange {
          min
          max
        }
        serviceId
      }
      programAnalyticsQuickDetails {
        locationIds
        dateRange {
          min
          max
        }
      }
      confirmedCasesTimeseriesChart {
        locationIds
        dateRange {
          min
          max
        }
        serviceId
      }
      serviceCasesByTypeChart {
        locationIds
        dateRange {
          min
          max
        }
        outcomeType
      }
      overallConfirmationRate {
        dateRange {
          min
          max
        }
        locationIds
        state
      }
      overallMultipleConfirmationRate {
        dateRange {
          min
          max
        }
        locationIds
        state
      }
      totalDrawdown {
        dateRange {
          min
          max
        }
        locationIds
        state
      }
      outcomesByTypeChart {
        dateRange {
          min
          max
        }
        locationIds
        state
      }
      activitiesByType {
        dateRange {
          min
          max
        }
        locationIds
        state
      }
      drawdown {
        dateRange {
          min
          max
        }
        locationIds
        state
      }
      kpiQuickDetails {
        dateRange {
          min
          max
        }
        locationIds
        state
      }
    }
 }`)

// KPI chart #1 (from top left) "Confirmation Rate"
export const OVERALL_CONFIRMATION_RATE = gql(`
  query getOverallConfirmationRate(
    $filter: ConfirmationRateFilterInput
    $dateRange: DateTimeRangeInput!
  ) {
    overallConfirmationRate(filter: $filter, dateRange: $dateRange) {
      rate
    }
 }`)

// KPI chart #2 "Multiple Confirmation Rate"
export const OVERALL_MULTIPLE_CONFIRMATION_RATE = gql(`
  query getOverallMultipleConfirmationRate(
    $filter: ConfirmationRateFilterInput
    $dateRange: DateTimeRangeInput!
  ) {
    overallMultipleConfirmationRate(filter: $filter, dateRange: $dateRange) {
      rate
    }
 }`)

// KPI Chart #3 Total Drawdown
export const TOTAL_DRAWDOWN = gql(`
  query getTotalDrawdown(
    $dateRange: DateTimeRangeInput!
    $filter: DrawdownFilterInput
  ) {
    totalDrawdown(dateRange: $dateRange, filter: $filter) {
      amount
    }
 }`)
export const TOTAL_POTENTIAL_BENEFIT = gql(`
  query totalPotentialAnnualBenefit(
    $dateRange: DateTimeRangeInput!
    $filter: KpiFilterInput
  ) {
    totalPotentialAnnualBenefit(dateRange: $dateRange, filter: $filter) {
    totalCashBenefit
    totalNonCashBenefit
      amount
    }
 }`)

// KPI Chart #4 Outcomes
export const ACTIVITY_BY_OUTCOMES_CHART = gql(`
  query getActivityByOutcomeChart(
    $dateRange: DateTimeRangeInput
    $filter: NotesFilterInput
  ) {
    outcomesByTypeChart(dateRange: $dateRange, filter: $filter) {
      confirmed
      denied
      total
    }
 }`)

// KPI Chart #5 Activities
export const ACTIVITIES_BY_TYPE = gql(`
  query getActivitiesByType(
    $dateRange: DateTimeRangeInput!
    $filter: NotesFilterInput
  ) {
    activitiesByType(dateRange: $dateRange, filter: $filter) {
      applicationSubmissionCount
      benefitMaintenanceCount
      clientAdvocacyCount
      documentAssistanceCount
      outcomeCount
      referralCount
      total
    }
 }`)

// KPI Chart #6 Drawdown
export const DRAWDOWN = gql(`
  query getDrawdownTimeSeriesChart(
    $dateRange: DateTimeRangeInput!
    $filter: DrawdownFilterInput
  ) {
    drawdownTimeSeriesChart(dateRange: $dateRange, filter: $filter) {
      timeSeries {
        y
        x
      }
      groupedBy
    }
 }`)

// KPI Chart #7
export const KPI_QUICK_DETAILS = gql(`
  query getKpiQuickDetails(
    $dateRange: DateTimeRangeInput!
    $filter: KpiFilterInput
  ) {
    kpiQuickDetails(dateRange: $dateRange, filter: $filter) {
      averageHouseholdDrawdownAmount
      averageHouseholdEligibility
      averageIndividualDrawdownAmount
      confirmationRate
      individualsAddedCount
      individualsCompletedScreeningCount
      individualsServedCount
      individualsWithConfirmationsCount
      individualsWithLikelyEligibleResultsCount
      individualsWithMultipleConfirmationsCount
      multipleConfirmationRate
      totalConfirmationsCount
      totalEnrollmentsCount
    }
 }`)
export const DRAWDOWN_BY_CASE_REPORT = gql(`
  query drawdownByCaseReport(
    $filter: DrawdownByCaseReportFilterInput
    $pageSize: Int
    $pageNumber: Int
  ) {
    drawdownByCaseReport(
      filter: $filter
      pageSize: $pageSize
      pageNumber: $pageNumber
    ) {
      totalCount
      pageCount
      nodes {
        id
        serviceId
        caseName
        caseType
        cashBenefit
        confirmationType
        drawdownAmount
        enrollmentCount
        displayCaseType @client
        displayCashBenefit @client
        displayConfirmationType @client
        displayDrawdownAmount @client
      }
    }
 }`)

export const AUTHORIZE = gql(`
  query authorize($input: PermissionInput!) {
    authorize(input: $input) {
      id
      authorized
      action
    }
 }`)

export const GET_GENERAL_NOTES = gql(`
  query getGeneralNotes(
    $pageSize: Int
    $pageNumber: Int
    $filter: GeneralNotesReportFilterInput
    $sort: [GeneralNotesReportSortInput!]
    $searchTerm: String
  ) {
    generalNotesReport(
      pageSize: $pageSize
      pageNumber: $pageNumber
      sort: $sort
      filter: $filter
      searchTerm: $searchTerm
    ) {
      totalCount
      pageCount
      nodes {
        id
        createdAt
        updatedAt
        displayCreatedAt @client
        displayUpdatedAt @client
        body
        summary
        noteAttachmentsCount
        caseManager {
          fullName
        }
        clientLocation {
          id
          location {
            name
          }
          client {
            fullName
            email
            primaryPhone
            alternatePhone
            alternateEmail
            displayContactInformation @client
            displayAlternatePhone @client
            household {
              id
            }
          }
        }
      }
    }
 }`)
